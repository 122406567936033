import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { CircularProgress, Typography } from "@material-ui/core";
import OffersController from '../shared/OffersController';
import CarouselShared from '../shared/Carousel';
import HeroBannerImg from '../shared/assets/New/background_4.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative"
  },
  borderBottomPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  heroBannerContainerOuter: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    maxHeight: "calc(50vh - 105px)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  heroBannerContainerInner: {
    padding: "20%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      padding: "25px"
    }
  },
  carouselContainer: {
    width: "100%",
    padding: "100px 50px",
    paddingTop: "0 !important",
    "@media (max-width: 500px)": {
      padding: "50px 20px"
    }
  }
}));

const SpecialOffers = () => {

  const classes = useStyles();
  const history = useHistory();

  const isCampaignsPage = history.location.pathname.toLowerCase().includes("campaigns");

  const { loading, offers, hiddenOffers } = OffersController();

  const [offersList, setOffersList] = useState([]);

  useEffect(() => {

    let tempOffers = isCampaignsPage ? hiddenOffers : offers;

    if (!tempOffers?.length > 0) return;

    tempOffers.forEach(offer => {

      if (offer.redirectUrl?.length > 0 && !offer.redirectUrl.toLowerCase().includes("special")) {
        // Custom redirect URL is provided, leave as-is
        offer.targetUrl = offer.redirectUrl;
        return;
      }

      offer.targetUrl = `${history.location.pathname}/${offer.id}`;

    });

    setOffersList(tempOffers || []);

  }, [offers, hiddenOffers]);

  return (
    <div className={classes.root}>

      <div
        className={classes.heroBannerContainerOuter}
        style={{ backgroundImage: `linear-gradient( rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.2) ), url(${HeroBannerImg})` }}
      >
        <div className={classes.heroBannerContainerInner}>
          <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
            our exclusive
          </Typography>
          <Typography variant='h5' gutterBottom className={`${classes.sectionHeadingSmall} ${classes.borderBottomPrimary}`}>
            Special offers
          </Typography>
        </div>
      </div>

      <div className={classes.contentTitleContainer}>
        {
          loading === true
            ? <CircularProgress size={100} />
            : <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey" }}>
              {offersList?.length > 0 ? "Our latest special offerings" : "No offers available"}
            </Typography>
        }
      </div>

      {
        !loading && offersList.length > 0 &&
        <div className={classes.carouselContainer}>
          <CarouselShared
            slides={offersList}
            isOffer
            visibleSlides={3}
            fullWidth={false}
          />
        </div>
      }

    </div>
  );
};

export default SpecialOffers;