import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import HeroBannerImg from '../shared/assets/New/background_5.png';
import WarrantyIcon from '../shared/assets/New/icon_warranty_1.png';
import HistoryIcon from '../shared/assets/New/icon_road.png';
import ConditionIcon from '../shared/assets/New/icon_vehicle_lifted.png';
import ServiceIcon from '../shared/assets/New/icon_vehicle_heart.png';
import AssistanceIcon from '../shared/assets/New/icon_cellphone.png';
import BreakdownIcon from '../shared/assets/New/icon_calendar.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    paddingBottom: "50px",
    "@media (max-width: 550px)": {
      paddingBottom: "25px"
    },
  },
  borderBottomPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionText: {
    fontSize: "1.2rem",
    "@media (max-width: 400px)": {
      fontSize: "0.8rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  contentTextSmallBold: {
    fontSize: '15px',
    fontWeight: '600',
    "@media (max-width: 400px)": {
      fontSize: "11px",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "13px",
    }
  },
  contentTextSmall: {
    fontSize: "13px",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "9px",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "11px"
    }
  },
  heroBannerContainerOuter: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    maxHeight: "calc(50vh - 105px)",
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroBannerContainerInner: {
    padding: "20%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      padding: "25px"
    }
  },
  contentTitleDescription: {
    width: "100%",
    paddingTop: "25px",
    "@media (max-width: 500px)": {
      padding: "15px"
    }
  },
  contentBlocksContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center"
  },
  contentBlock: {
    width: "calc((100% / 3) - 60px)",
    minWidth: "375px",
    maxWidth: "450px",
    aspectRatio: 1,
    padding: "20px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    borderRadius: "10px",
    boxShadow: `0px 0px 5px 0 ${theme.palette.secondary.main}3`,
    "&:hover": {
      boxShadow: `0px 0px 5px 0 ${theme.palette.primary.main}`
    },
    "@media (max-width: 400px)": {
      minWidth: "250px",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      minWidth: "300px",
    }
  },
  icon: {
    width: "75px"
  }
}));

const ApprovedBenefits = () => {

  const classes = useStyles();

  return (
    <div className={classes.root}>

      <div
        className={classes.heroBannerContainerOuter}
        style={{ backgroundImage: `linear-gradient( rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.2) ), url(${HeroBannerImg})` }}
      >
        <div className={classes.heroBannerContainerInner}>
          <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
            certified pre-loved vehicles
          </Typography>
          <Typography variant='h5' gutterBottom className={`${classes.sectionHeadingSmall} ${classes.borderBottomPrimary}`}>
            Approved benefits
          </Typography>
        </div>
      </div>

      <div className={classes.contentTitleContainer}>
        <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey" }}>
          Approved pre-loved renault benefits
        </Typography>

        <div className={classes.contentTitleDescription}>
          <Typography className={classes.sectionText} align="center">
            Discover the benefits of our approved used programme and enjoy complete peace of mind.
            Your Renault Selection deler will provide a minimum 12 month warranty, breakdown assistance,
            30 days exchange promise, history checks, condition checks and driveaway insurance.
          </Typography>
        </div>
      </div>

      <div className={classes.contentBlocksContainer}>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={WarrantyIcon} />
          <Typography variant='h5' className={classes.contentTextSmallBold} style={{ margin: "20px 0" }}>
            Warranty
          </Typography>
          <Typography className={classes.contentTextSmall} align="center">
            All Renault Selection certified pre-owned vehicles come with the balance of our 5yr/150 000 km
            manufacturere warranty to give you complete peace of mind.
          </Typography>
        </div>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={HistoryIcon} />
          <Typography variant='h5' className={classes.contentTextSmallBold} style={{ margin: "20px 0" }}>
            History checks
          </Typography>
          <Typography className={classes.contentTextSmall} align="center">
            All Renault Selection certified pre-owned vehicles have their history checked to give you complete
            confidence that the vehicle you purchase has not been recorded as stolen or written off. A certificate
            is available from your Renault Dealer upon request.
          </Typography>
        </div>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={ConditionIcon} />
          <Typography variant='h5' className={classes.contentTextSmallBold} style={{ margin: "20px 0" }}>
            Condition checks
          </Typography>
          <Typography className={classes.contentTextSmall} align="center">
            Prior to collecting your Renault Selection certified used vehicle, your Renault Dealer will undertake a
            mechanical and safety check to ensure it is ready for the road and will provide you with a copy of the workshop paperwork.
          </Typography>
        </div>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={ServiceIcon} />
          <Typography variant='h5' className={classes.contentTextSmallBold} style={{ margin: "20px 0" }}>
            Service plans & accessories
          </Typography>
          <Typography className={classes.contentTextSmall} align="center">
            When choosing how to finance your Renault Selection certified used vehicle, your Renault Dealer will be
            able to discuss service plans, warranty boosters or accessories for your vehicle to suit your individual needs.
          </Typography>
        </div>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={AssistanceIcon} />
          <Typography variant='h5' className={classes.contentTextSmallBold} style={{ margin: "20px 0" }}>
            24hr roadside assistance
          </Typography>
          <Typography className={classes.contentTextSmall} align="center">
            Whatever the emergency, we'll always be there. Because your personal safety is of utmost importance to us,
            help is one phone call away. Simply dial 0861 736 2858 for assistance or for a team to be dispatched to you.
            Our 24-hour Roadside Assistance Plan covers all Renault Selection certified pre-owned vehicles for the balance
            of our 5yr/150 000 km manufacturer warranty. Should you wish to top up your cover for a longer period your
            Renault Dealer will be happy to help.
          </Typography>
        </div>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={BreakdownIcon} />
          <Typography variant='h5' className={classes.contentTextSmallBold} style={{ margin: "20px 0" }}>
            12 Month breakdown assistance
          </Typography>
          <Typography className={classes.contentTextSmall} align="center">
            Your Renault Selection certified pre-owned vehicle will be supplied with 12 months Breakdown Assistance as standard.
            Should you wish to top up your cover for a longer period your Renault Dealer will be happy to help.
          </Typography>
        </div>

      </div>

    </div>
  );
};

export default ApprovedBenefits;