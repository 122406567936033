export const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

export const currencyFormatter = new Intl.NumberFormat('af', {
  style: 'currency',
  currency: 'ZAR',
  minimumFractionDigits: 2
});

export const mileageFormatter = new Intl.NumberFormat('af', {
  style: 'unit',
  unit: "kilometer",
  minimumFractionDigits: 0
});

export function removeDuplicates(data, key) {
  return [
    ...new Map(
      data.map(x => [key(x), x])
    ).values()
  ];
}

export function calculateMonthlyInstallment(price, depositPerc = 0, balloonPerc = 10, interestRate = 9, term = 72) {

  let deposit = price * (depositPerc / 100);
  let balloon = price * (balloonPerc / 100);
  let initFee = 0;
  let principal = price - deposit + initFee;
  let interestPM = interestRate / 100 / 12;

  let v = 1 / (1 + interestPM);
  let d = 1 - v;
  let fp = principal * Math.pow(1 + interestPM, 1 / (365 / 12).toFixed());
  let monthly = 0;

  if (parseInt(balloon) > 0) {
    let comp = (1 - Math.pow(v, term - 1)) / d;
    monthly = (fp - balloon * Math.pow(v, term - 1)) / comp;
  } else {
    let comp = (1 - Math.pow(v, term)) / d;
    monthly = fp / comp;
  }

  return round(monthly) + initFee;

}

export function round(x) {

  return Math.round(x * 100) / 100;

}

export function cleanDealerName(dealerName) {

  return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-');

}