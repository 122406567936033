import React, { useEffect, useState, useContext } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Close, Search } from '@material-ui/icons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MASTER_DEALERID } from "../shared/Constants";
import { DealerContext } from "../shared/contexts/DealerContext";
import { cleanDealerName } from "../shared/UtilityFunctions";
import HeroBannerImg from '../shared/assets/New/background_7.png';

const hiddenDealerIds = [
  MASTER_DEALERID,
  295,
  349,
  348,
  512,
  513,
  347,
  346,
  345,
  514,
  319,
  343,
  515,
  516,
  341,
  340,
  339,
  517,
  338,
  336,
  335,
  350,
  351,
  352,
  353,
  369,
  368,
  367,
  366,
  365,
  518,
  519,
  520,
  521,
  522,
  277,
  523,
  363,
  524,
  362,
  360,
  358,
  357,
  356,
  355,
  354,
  334,
  361,
  325,
  322,
  526,
  527,
  309,
  308,
  440,
  307,
  306,
  305,
  304,
  303,
  529,
  302,
  301,
  300,
  299,
  298,
  296,
  530,
  371,
  531,
  312,
  331
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative"
  },
  borderBottomGrey: {
    borderBottom: "1px solid grey"
  },
  borderBottomPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  contentTextBold: {
    fontSize: "19px",
    fontWeight: "600",
    "@media (max-width: 400px)": {
      fontSize: "13px",
    },
    "@media (min-width: 401px) and (max-width: 600px)": {
      fontSize: "15px",
    },
    "@media (min-width: 601px) and (max-width: 800px)": {
      fontSize: "17px"
    }
  },
  contentText: {
    fontSize: "17px",
    "@media (max-width: 400px)": {
      fontSize: "11px",
    },
    "@media (min-width: 401px) and (max-width: 600px)": {
      fontSize: "13px",
    },
    "@media (min-width: 601px) and (max-width: 800px)": {
      fontSize: "15px"
    }
  },
  contentTextSmallBold: {
    fontSize: '15px',
    fontWeight: '600',
    "@media (max-width: 550px)": {
      fontSize: "11px",
    },
    "@media (min-width: 551px) and (max-width: 1000px)": {
      fontSize: "13px"
    }
  },
  contentTextSmall: {
    fontSize: "13px",
    textTransform: "capitalize",
    "@media (max-width: 550px)": {
      fontSize: "9px",
    },
    "@media (min-width: 551px) and (max-width: 1000px)": {
      fontSize: "11px"
    }
  },
  heroBannerContainerOuter: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    maxHeight: "calc(50vh - 105px)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  heroBannerContainerInner: {
    padding: "20%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      padding: "25px"
    }
  },
  contentContainer: {
    width: "100%",
    padding: "50px 50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      padding: "25px 20px"
    }
  },
  searchContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  searchTitleContainer: {
    width: "48%",
    padding: "10px 20px",
    background: theme.palette.secondary.main,
    "@media (max-width: 800px)": {
      width: "100%"
    }
  },
  searchTitle: {
    color: "white",
    textTransform: "uppercase"
  },
  searchFieldsContainer: {
    width: "48%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      width: "100%",
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  searchFieldContainer: {
    width: "100%",
    color: "grey",
    "@media (max-width: 800px)": {
      width: "100%",
      margin: "20px 0"
    }
  },
  searchClearIconContainer: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  dealersContainer: {
    width: "100%",
    marginTop: "20px"
  },
  dealerContainer: {
    width: "100%",
    padding: "10px 15px",
    transition: "all 1s"
  },
  dealerHeadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer"
  },
  dealerHeading: {
    color: "grey",
    fontWeight: "600"
  },
  dealerIcon: {
    width: "40px !important",
    color: "grey",
    transition: "transform 0.5s ease-in !important"
  },
  dealerContentContainer: {
    width: "100%",
    maxHeight: "0",
    overflow: "hidden",
    transition: "max-height 0.5s",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      flexDirection: "column-reverse",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  dealerLocationContainer: {
    width: "48%",
    "@media (max-width: 800px)": {
      width: "100%",
      marginTop: "20px"
    }
  },
  dealerLocation: {
    width: "100%",
    height: "100%",
    aspectRatio: 16 / 9
  },
  dealerInfoContainer: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      width: "100%"
    }
  },
  dealerNameHeadingContainer: {
    paddingBottom: "10px",
    marginBottom: "20px"
  },
  dealerNameHeading: {
    fontWeight: "600"
  },
  dealerInfoGroup: {
    margin: "10px 0"
  },
  dealerInfoButtonContainer: {
    marginTop: "20px"
  },
  dealerButton: {
    width: "150px",
    "@media (max-width: 550px)": {
      fontSize: "11px",
    },
    "@media (min-width: 551px) and (max-width: 650px)": {
      fontSize: "13px",
    }
  },
  secondaryButton: {
    color: "white",
    border: "none",
    background: theme.palette.secondary.main,
    "&:hover": {
      color: "black",
      background: theme.palette.primary.main,
    }
  },
}));

const OurDealers = () => {

  const classes = useStyles();

  const { loading: loadingDealers, dealers: loadedDealers } = useContext(DealerContext);

  const [allDealers, setAllDealers] = useState([]);
  const [searchedDealers, setSearchedDealers] = useState([]);
  const [dealerNameSearchText, setDealerNameSearchText] = useState("");
  const [selectedDealerIndex, setSelectedDealerIndex] = useState(-1);

  useEffect(() => {

    if (loadingDealers === true) return;

    setAllDealers(loadedDealers?.filter(d => !hiddenDealerIds.includes(d.dealerId)));

  }, [loadingDealers, loadedDealers]);

  useEffect(() => {

    if (allDealers?.length > 0) {

      let result = allDealers?.filter(d => d?.name?.toLowerCase()?.includes(dealerNameSearchText?.toLowerCase()));
      setSearchedDealers(result || []);

    } else {

      setSearchedDealers([]);

    }

  }, [allDealers, dealerNameSearchText]);

  const searchDealerNames = (e) => {

    setDealerNameSearchText(e?.target?.value);

  };

  const selectDealer = (dealerIndex) => {

    setSelectedDealerIndex(dealerIndex === selectedDealerIndex ? -1 : dealerIndex);

  };

  const viewDealership = (dealerId) => {

    const selectedDealer = allDealers.find(d => d.dealerId === dealerId);

    window.location.href = `/dealer/${cleanDealerName(selectedDealer.name)}/`;

  };

  return (
    <div className={classes.root}>

      <div
        className={classes.heroBannerContainerOuter}
        style={{ backgroundImage: `linear-gradient( rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.2) ), url(${HeroBannerImg})` }}
      >
        <div className={classes.heroBannerContainerInner}>
          <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
            find your nearest
          </Typography>
          <Typography variant='h5' gutterBottom className={`${classes.sectionHeadingSmall} ${classes.borderBottomPrimary}`}>
            Renault selection dealership
          </Typography>
        </div>
      </div>

      <div className={classes.contentContainer}>

        <div className={classes.searchContainer}>

          <div className={classes.searchTitleContainer}>
            <Typography className={`${classes.searchTitle} ${classes.contentTextBold}`}>
              Contact details
            </Typography>
          </div>

          <div className={classes.searchFieldsContainer}>

            <div className={classes.searchFieldContainer}>
              <TextField
                id="dealerNameSearchText"
                fullWidth
                autoFocus
                variant="outlined"
                style={{ marginBottom: "0" }}
                placeholder="Search dealership name"
                value={dealerNameSearchText}
                onChange={searchDealerNames}
                inputProps={{
                  style: { padding: "13px" },
                }}
                InputProps={{
                  startAdornment: (
                    <Search style={{ color: "grey" }} />
                  ),
                  endAdornment: (
                    <Close
                      className={classes.searchClearIconContainer}
                      onClick={() => setDealerNameSearchText("")}
                      style={{ display: dealerNameSearchText?.length > 0 ? "block" : "none" }}
                    />
                  ),
                }
                }
              />
            </div>

          </div>

        </div>

        <div className={classes.dealersContainer}>
          {
            loadingDealers === true
              ? <div className={classes.contentTitleContainer}>
                <CircularProgress size={100} />
              </div>
              : !(searchedDealers?.length > 0) &&
              <div className={classes.contentTitleContainer}>
                <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey" }}>
                  No dealerships found
                </Typography>
              </div>
          }
          {
            searchedDealers?.length > 0 && searchedDealers.map((dealer, d) => (
              <div key={d} className={`${classes.dealerContainer} ${classes.borderBottomGrey}`}>

                <div className={classes.dealerHeadingContainer} onClick={() => { selectDealer(d) }}>

                  <Typography className={`${classes.dealerHeading} ${classes.contentText}`}>
                    {dealer.name}
                  </Typography>

                  <ExpandMoreIcon
                    className={`${classes.dealerIcon}`}
                    style={selectedDealerIndex === d ? { transform: "rotate(180deg)" } : {}}
                  />

                </div>

                <div
                  className={classes.dealerContentContainer}
                  style={selectedDealerIndex === d ? { maxHeight: "1000px", padding: "20px" } : {}}
                >

                  <div className={classes.dealerLocationContainer}>
                    <iframe className={classes.dealerLocation} src={`https://maps.google.com/maps?q=${dealer.addressLongitude},${dealer.addressLatitude}&via=0&z=16&output=embed`} />
                  </div>

                  <div className={classes.dealerInfoContainer}>

                    <div className={`${classes.dealerNameHeadingContainer} ${classes.borderBottomGrey}`}>
                      <Typography className={`${classes.dealerNameHeading} ${classes.contentText}`}>
                        {dealer.name}
                      </Typography>
                    </div>

                    <div className={classes.dealerInfoGroup}>
                      <Typography className={classes.contentTextSmallBold}>Address:</Typography>
                      <Typography className={classes.contentTextSmall}>{dealer.address?.postalAddress || dealer.address?.streetAddress}</Typography>
                    </div>

                    <div className={classes.dealerInfoGroup}>
                      <Typography className={classes.contentTextSmallBold}>Business Hours</Typography>
                      <Typography className={classes.contentTextSmall}>Monday - Friday: 8:00-17:00</Typography>
                      <Typography className={classes.contentTextSmall}>Saturday: 8:00-13:00</Typography>
                    </div>

                    <div className={classes.dealerInfoButtonContainer}>
                      <Button className={`${classes.dealerButton} ${classes.secondaryButton}`} onClick={() => { viewDealership(dealer.dealerId) }}>
                        view dealership
                      </Button>
                    </div>

                  </div>

                </div>

              </div>
            ))
          }
        </div>

      </div>

    </div>
  );
};

export default OurDealers;