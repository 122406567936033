import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    width: "100%",
    position: "relative",
    margin: "auto"
  },
  slideContainer: {
    width: props => props.fullWidth === true ? "100%" : "90%",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: "auto"
  },
  slide: {
    width: "calc(100% - 10px)",
    margin: "auto",
    "&:hover": {
      cursor: "pointer"
    }
  },
  slideDefault: {
    paddingBottom: props => `calc((${props.fullWidth === true ? "100vw" : "90vw"} - 50px) / ${props.numSlides} * 9/16) !important`
  },
  carouselBtn: {
    width: "calc(5% - 10px)",
    minWidth: "15px",
    aspectRatio: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: 0.7,
    color: theme.palette.secondary.main,
    background: 'transparent',
    border: props => props.fullWidth === true ? `${theme.palette.secondary.main} 5px solid` : "none",
    borderRadius: "50%",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      background: props => props.fullWidth === true ? theme.palette.secondary.main : "transparent",
      opacity: 1
    }
  },
  carouselBtnBack: {
    left: '10px',
  },
  carouselBtnNext: {
    right: '10px'
  },
  carouselBtnIcon: {
    fontSize: "40px"
  },
  carouselDots: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    '& > button': {
      width: '15px',
      height: '15px',
      borderRadius: '50%',
      border: 'none',
      padding: '0',
      cursor: 'pointer',
      backgroundColor: '#EFDF00',
      marginRight: '10px',
    }
  },
  backgroundImage: {
    backgroundPosition: 'center center',
    backgroundSize: '100% 100%!important',
    '@media (max-width: 624px)': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
      height: '36%'
    },
  },
}));

const defaultProps = {
  naturalSlideWidth: 100,
  naturalSlideHeight: 60,
  isPlaying: true,
  infinite: true
};

const Carousel = ({ slides, isOffer, innerClass, dots, fullWidth = true, ...props }) => {

  const history = useHistory();
  const carouselPausedRef = useRef(false);
  const carouselVisibleSlidesRef = useRef(!props.visibleSlides || window.innerWidth < 700 ? 1 : Math.min(props.visibleSlides, slides?.length));
  const [carouselProps, setCarouselProps] = useState({
    ...defaultProps,
    ...props,
    visibleSlides: carouselVisibleSlidesRef.current,
    totalSlides: slides?.length || 0
  });

  const classes = useStyles({ dots, fullWidth, numSlides: carouselVisibleSlidesRef.current });

  useEffect(() => {

    window.addEventListener('resize', handleResize);

  }, []);

  const handleResize = () => {

    // Ensure that only 1 slide is visible for screen sizes smaller than 700px
    if (window.innerWidth < 700 && carouselVisibleSlidesRef.current > 1) {

      carouselVisibleSlidesRef.current = 1;
      setCarouselProps({
        ...carouselProps,
        visibleSlides: carouselVisibleSlidesRef.current
      });


    } else if (window.innerWidth >= 700 && carouselVisibleSlidesRef.current <= 1) {

      carouselVisibleSlidesRef.current = !props.visibleSlides ? 1 : Math.min(props.visibleSlides, slides?.length);
      setCarouselProps({
        ...carouselProps,
        visibleSlides: carouselVisibleSlidesRef.current
      });

    }

  };

  const goToTargetUrl = (url) => {

    history.push(url);

  };

  const handleVideoStart = () => {

    carouselPausedRef.current = true;

  };

  const handleVideoEnded = (e) => {

    carouselPausedRef.current = false;

  };

  const renderSlide = (slide) => {

    let targetUrl = slide.targetUrl ?? (isOffer
      ? `special-offers/${slide.id}`
      : slide.url ?? "/apply-for-finance");

    let sourceValue = isOffer
      ? slide.imageUrl
      : slide.slideImageUrl ?? slide.img;

    return (
      <div className={classes.slide} onClick={() => goToTargetUrl(targetUrl)}>
        {
          slide.isVideo === true
            ? <video controls={false} width="100%" autoPlay muted onEnded={handleVideoEnded} onPlay={handleVideoStart}>
              <source type='video/mp4' src={sourceValue} />
              <source type="video/webm" src={sourceValue} />
              <source type="video/ogg" src={sourceValue} />
              Your browser does not support this video.
            </video>
            : <img className={classes.imgHolder} width="100%" tag="div" src={sourceValue} />
        }
      </div>
    );

  };

  return (
    <CarouselProvider
      {...carouselProps}
      isPlaying={carouselPausedRef.current !== true}
      className={classes.sliderContainer}
    >
      <Slider className={classes.slideContainer}>
        {
          slides?.length > 0 && slides.map((slide, index) =>
            <Slide key={index} index={index} className={classes.slideDefault} innerClassName={innerClass}>
              {
                renderSlide(slide)
              }
            </Slide>
          )
        }
      </Slider>

      {
        dots === true &&
        <DotGroup className={classes.carouselDots} disableActiveDots={false} />
      }

      <ButtonBack className={`${classes.carouselBtn} ${classes.carouselBtnBack}`}><ChevronLeftIcon className={classes.carouselBtnIcon} /></ButtonBack>
      <ButtonNext className={`${classes.carouselBtn} ${classes.carouselBtnNext}`}><ChevronRightIcon className={classes.carouselBtnIcon} /></ButtonNext>
    </CarouselProvider>
  );
};

export default Carousel;