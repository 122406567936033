import React, { createContext, useState } from 'react';

export const FavouriteContext = createContext();

export const FavouriteProvider = ({ children }) => {

  const [favouriteVal, setFavouriteVal] = useState([]);
  const [favourites, setFavourites] = useState([]);

  const addFavourite = (vehicle) => {

    vehicle.isFavourite = true;
    let tempFavourites = [
      ...favourites,
      vehicle
    ];

    setFavourites(tempFavourites.sort((a, b) => (a.model || a.modelRange) > (b.model || b.modelRange) ? 1 : -1));

  }

  const removeFavourite = (vehicle) => {

    vehicle.isFavourite = false;
    let tempFavourites = favourites.filter(f => f.stockId != vehicle.stockId);

    setFavourites(tempFavourites.sort((a, b) => (a.model || a.modelRange) > (b.model || b.modelRange) ? 1 : -1));

  }

  return (
    <FavouriteContext.Provider value={{ favouriteVal, setFavouriteVal, favourites, addFavourite, removeFavourite }}>
      {children}
    </FavouriteContext.Provider>
  )
};
