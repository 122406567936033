import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from "notistack";
import { FavouriteContext } from '../contexts/FavouriteContext';
import { removeDuplicates } from '../UtilityFunctions';
import SortButton from './VehicleFilterSortButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  flexRowContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  labelContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    background: "lightGrey",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: "none",
    padding: "10px 30px",
    transform: "translateY(1px)"
  },
  label: {
    color: "black",
    margin: "0",
    fontWeight: "600",
    fontSize: "1rem",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  selectorLayoutContainer: {
    "@media (max-width: 800px)": {
      flexWrap: "wrap"
    },
  },
  selectorGroupContainerOuter: {
    width: "100%",
    padding: "10px 3%",
    background: "white",
    border: `1px solid ${theme.palette.secondary.main}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      flexWrap: "wrap"
    },
    "@media (min-width: 800px)": {
      borderRight: "none"
    }
  },
  selectorGroupContainerInner: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 400px)": {
      flexWrap: "wrap"
    }
  },
  selectorContainer: {
    width: "100%",
    minWidth: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  selector: {
    borderBottom: "none",
    getContentAnchorEl: null,
  },
  selectorInput: {
    getContentAnchorEl: null,
  },
  selectorClearIconContainer: {
    color: theme.palette.primary.main,
    transform: "translateX(-20px)",
    cursor: "pointer"
  },
  verticalDivider: {
    width: "2px",
    height: "70px",
    margin: "0 2%",
    background: "#0003",
    "@media (max-width: 400px)": {
      display: "none"
    }
  },
  verticalDividerConditional: {
    "@media (max-width: 600px)": {
      display: "none"
    }
  },
  searchButtonContainer: {
    padding: "10px 3%",
    background: "white",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderLeft: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 800px)": {
      marginTop: "20px",
      background: "transparent",
      border: "none",
    },
    "@media (min-width: 800px)": {
      paddingLeft: "0"
    }
  },
  primaryButton: {
    background: theme.palette.primary.main,
    padding: "20px 50px",
    marginLeft: "2%",
    height: "70px",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    cursor: "pointer",
    "@media (max-width: 800px)": {
      marginLeft: "0",
    }
  },
  primaryButtonLabel: {
    color: theme.palette.secondary.main,
    margin: 0
  },
  buttonGroupContainerOuter: {
    marginTop: "10px",
    "@media (max-width: 600px)": {
      justifyContent: "space-around"
    },
    "@media (max-width: 750px)": {
      flexWrap: "wrap"
    }
  },
  buttonGroupContainerInner: {
    "@media (max-width: 600px)": {
      //flexDirection: "column"
      width: "100%"
    }
  },
  transparentButton: {
    width: "100px",
    color: "white",
    background: "transparent",
    border: "1px solid white",
    margin: "10px",
    "&:hover": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    "@media (max-width: 600px)": {
      width: "200px"
    }
  },
  activeButton: {
    background: theme.palette.primary.main,
    "&:hover": {
      background: "transparent"
    }
  },
  sortButton: {
    width: "150px",
    color: "black",
    background: "white",
    "&:hover": {
      background: "transparent"
    }
  },
  sortMenu: {
    marginTop: "5px",
    borderColor: theme.palette.primary.main,
    maxWidth: "90vw"
  },
  sortItem: {
    "&:hover": {
      background: `${theme.palette.primary.main}88`
    }
  },
  sortItemSelected: {
    fontWeight: "600",
    background: `${theme.palette.primary.main}88`
  }
}));

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
    },
  },
};

const defaultPriceRanges = [
  { min: 0, max: 25000 },
  { min: 25000, max: 50000 },
  { min: 50000, max: 75000 },
  { min: 75000, max: 100000 },
  { min: 100000, max: 125000 },
  { min: 125000, max: 150000 },
  { min: 150000, max: 175000 },
  { min: 175000, max: 200000 },
  { min: 200000, max: 225000 },
  { min: 225000, max: 250000 },
  { min: 250000, max: 275000 },
  { min: 275000, max: 300000 },
  { min: 300000, max: 325000 },
  { min: 325000, max: 350000 },
  { min: 350000, max: 375000 },
  { min: 375000, max: 400000 },
  { min: 400000, max: 425000 },
  { min: 425000, max: 450000 },
  { min: 450000, max: 475000 },
  { min: 475000, max: 500000 },
  { min: 500000, max: 750000 },
  { min: 750000, max: 2000000 }
]


const defaultInstallmentRanges = [
  { min: 1000, max: 1500 },
  { min: 1500, max: 2000 },
  { min: 2000, max: 2500 },
  { min: 2500, max: 3000 },
  { min: 3000, max: 3500 },
  { min: 3500, max: 4000 },
  { min: 4000, max: 4500 },
  { min: 4500, max: 5000 },
  { min: 5000, max: 5500 },
  { min: 5500, max: 6000 },
  { min: 6000, max: 6500 },
  { min: 6500, max: 7000 },
  { min: 7000, max: 7500 },
  { min: 7500, max: 8000 },
  { min: 8000, max: 8500 },
  { min: 8500, max: 9000 },
  { min: 9000, max: 9500 },
  { min: 9500, max: 10000 },
  { min: 10000, max: 20000 }
];

const sortOptions = [
  {
    id: 1,
    name: "Default",
    label: "Default A - Z"
  },
  {
    id: 2,
    name: "Price",
    label: "Price low to high"
  },
  {
    id: 3,
    name: "Price",
    label: "Price high to low"
  },
  {
    id: 4,
    name: "Mileage",
    label: "Mileage low to high"
  },
  {
    id: 5,
    name: "Mileage",
    label: "Mileage high to low"
  },
  {
    id: 6,
    name: "Year",
    label: "Model year"
  }
];

const VehicleFilter = ({
  allowSort,
  allVehicles,
  filterState,
  processFilteredVehicles
}) => {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { favourites } = useContext(FavouriteContext);

  const isFiltered = filterState && Object.keys(filterState)?.length > 0;

  const [filteredVehicles, setFilteredVehicles] = useState(0);
  const [filteredState, setFilteredState] = useState({});
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedModel, setSelectedModel] = useState(filterState?.model ?? "");
  const [selectedVariant, setSelectedVariant] = useState(filterState?.variant ?? "");
  const [selectedYear, setSelectedYear] = useState(filterState?.year ?? "");
  const [selectedPrice, setSelectedPrice] = useState(filterState?.price ?? "");
  const [selectedInstalment, setSelectedInstalment] = useState(filterState?.instalment ?? "");
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const [years, setYears] = useState([]);
  const [prices, setPrices] = useState([]);
  const [instalments, setInstalments] = useState([]);
  const [isManualSelected, setIsManualSelected] = useState(filterState?.manual ?? false);
  const [isAutomaticSelected, setIsAutomaticSelected] = useState(filterState?.automatic ?? false);
  const [isPetrolSelected, setIsPetrolSelected] = useState(filterState?.petrol ?? false);
  const [isDieselSelected, setIsDieselSelected] = useState(filterState?.diesel ?? false);
  const [isFavouritesSelected, setIsFavouritesSelected] = useState(filterState?.favourite ?? false);

  useEffect(() => {

    getModels();
    getVariants();
    getYears();
    getPrices();
    getInstalments();

    if (isFiltered === true) {
      search();
    } else {
      applyFilter();
    }

  }, []);

  useEffect(() => {
    if (selectedModel && allVehicles?.length > 0) {
      const modelVehicles = allVehicles?.filter((v) => {
        return v?.model?.toLowerCase().includes(selectedModel?.toLowerCase()) ||
          v?.modelRange?.toLowerCase().includes(selectedModel?.toLowerCase())
      });

      const allVariants = modelVehicles.map((v) => v?.baseVariantName?.trim()?.toLowerCase()) || [];
      const sortedVariants = removeDuplicates(allVariants, m => m).sort((a, b) => a > b ? 1 : -1);

      setVariants(sortedVariants);
    }
  }, [selectedModel])


  const getModels = () => {

    const allModels = allVehicles.map((v) => (v.model || v.modelRange)?.trim()?.toLowerCase()?.split(" ")[0]) || [];
    const sortedModels = removeDuplicates(allModels, m => m).sort((a, b) => a > b ? 1 : -1);

    setModels(sortedModels);

  };

  const getVariants = () => {
    const allVariants = allVehicles.map((v) => (v?.baseVariant?.name)?.trim()?.toLowerCase()) || [];
    const sortedVariants = removeDuplicates(allVariants, m => m).sort((a, b) => a > b ? 1 : -1);

    setVariants(sortedVariants);
  }

  const getYears = () => {

    const allYears = allVehicles.map((v) => `${v.year}`) || [];
    const sortedYears = removeDuplicates(allYears, y => y).sort((a, b) => a > b ? 1 : -1);

    setYears(sortedYears);

  };

  const getPrices = () => {

    try {

      const prices = allVehicles.map((v) => v.price);
      const filteredRanges = defaultPriceRanges.filter(r => prices.some(p => p >= r.min && p < r.max));

      setPrices(filteredRanges);

    } catch {
      setPrices(defaultPriceRanges);
    }

  };

  const getInstalments = () => {

    try {

      const instalments = allVehicles.map((v) => calculateInstallment(v.price));
      const filteredRanges = defaultInstallmentRanges.filter(r => instalments.some(i => i >= r.min && i < r.max));

      setInstalments(filteredRanges);

    } catch {
      setInstalments(defaultInstallmentRanges);
    }

  };

  const selectModel = (e) => { setSelectedModel(e?.target?.value) };

  const selectVariant = (e) => { setSelectedVariant(e?.target?.value) };

  const selectYear = (e) => { setSelectedYear(e?.target?.value) };

  const selectPrice = (e) => { setSelectedPrice(e?.target?.value) };

  const selectInstalment = (e) => { setSelectedInstalment(e?.target?.value) };

  const toggleManual = () => { setIsManualSelected(!isManualSelected) };

  const toggleAutomatic = () => { setIsAutomaticSelected(!isAutomaticSelected) };

  const togglePetrol = () => { setIsPetrolSelected(!isPetrolSelected) };

  const toggleDiesel = () => { setIsDieselSelected(!isDieselSelected) };

  const toggleFavourites = () => { setIsFavouritesSelected(!isFavouritesSelected) };

  const sort = (sortOption) => {

    if (sortOption.id === selectedSort.id) return;

    const getSortCompareFn = (a, b) => {
      switch (sortOption.id) {
        //Default A - Z
        case 1: return (a.model || a.modelRange) > (b.model || b.modelRange) ? 1 : -1;
        //Price low to high
        case 2: return a.price > b.price ? 1 : -1;
        //Price high to low
        case 3: return a.price > b.price ? -1 : 1;
        //Mileage low to high
        case 4: return Number(a.mileage) > Number(b.mileage) ? 1 : -1;
        //Mileage high to low
        case 5: return Number(a.mileage) > Number(b.mileage) ? -1 : 1;
        //Model year
        case 6:
        default: return a.year > b.year ? 1 : -1;
      }
    };

    const sortedVehicles = [...filteredVehicles].sort((a, b) => getSortCompareFn(a, b));

    setSelectedSort(sortOption);
    setFilteredVehicles(sortedVehicles);
    processFilteredVehicles(sortedVehicles);

  };

  const search = () => {

    const { resultVehicles, resultState } = applyFilter();

    processFilteredVehicles(resultVehicles, resultState);

  };

  function applyFilter() {

    let defaultVehicles = isFavouritesSelected === true ? favourites : allVehicles;
    let resultVehicles = [...defaultVehicles];
    let resultState = {};

    try {

      if (!getIsSelectionMade()) {
        setFilteredVehicles(resultVehicles);
        setFilteredState(resultState);
        return { resultVehicles, resultState };
      }

      /*
          Logic: 
          1)  Filter the vehicles first by the model, year, price & instalment together in an "AND" configuration
          2)  Consecutively filter the result by considering the manual/automatic & petrol/diesel in separate pairs
      */

      if (selectedModel?.length > 0) {
        resultState.model = selectedModel;
        resultVehicles = resultVehicles.filter(v => (v.model || v.modelRange)?.trim()?.toLowerCase()?.includes(selectedModel));
      }


      if (selectedVariant?.length > 0) {
        resultState.variant = selectedVariant;
        resultVehicles = resultVehicles.filter(v => (v?.baseVariantName)?.trim()?.toLowerCase()?.includes(selectedVariant?.toLowerCase()));
      }

      if (selectedYear?.length > 0) {
        resultState.year = selectedYear;
        resultVehicles = resultVehicles.filter(v => `${v.year}` === selectedYear);
      }

      if (Object.keys(selectedPrice).length > 0) {
        resultState.price = selectedPrice;
        resultVehicles = resultVehicles.filter(v => (v.price >= selectedPrice.min && v.price <= selectedPrice.max));
      }

      if (Object.keys(selectedInstalment).length > 0) {
        resultState.instalment = selectedInstalment;
        resultVehicles = resultVehicles.filter(v => (calculateInstallment(v.price) >= selectedInstalment.min && calculateInstallment(v.price) <= selectedInstalment.max));
      }

      let filteredByTransmission = [];
      let filteredByFuelType = [];

      if (isManualSelected === true) {
        resultState.manual = true;
        filteredByTransmission = resultVehicles.filter(v => v.transmission?.toLowerCase().includes("manual"));
      }

      if (isAutomaticSelected === true) {
        resultState.automatic = true;
        filteredByTransmission = [
          ...filteredByTransmission,
          ...resultVehicles.filter(v => v.transmission?.toLowerCase().includes("automatic"))
        ];
      }

      // Apply the paired filtering of the transmission to the result if applicable
      if (isManualSelected === true || isAutomaticSelected === true) resultVehicles = [...filteredByTransmission];

      if (isPetrolSelected === true) {
        resultState.petrol = true;
        filteredByFuelType = resultVehicles.filter(v => v.fuelType?.toLowerCase().includes("petrol"));
      }

      if (isDieselSelected === true) {
        resultState.diesel = true;
        filteredByFuelType = [
          ...filteredByFuelType,
          ...resultVehicles.filter(v => v.fuelType?.toLowerCase().includes("diesel"))
        ];
      }

      // Apply the paired filtering of the fuel type to the result if applicable
      if (isPetrolSelected === true || isDieselSelected === true) resultVehicles = [...filteredByFuelType];

    } catch (error) {

      enqueueSnackbar(`Failed to search vehicles: ${error}`, { variant: "error" });
      resultVehicles = [...defaultVehicles];
      resultState = {};

    }

    setFilteredVehicles(resultVehicles);
    setFilteredState(resultState);
    return { resultVehicles, resultState };

  }

  function calculateInstallment(price) {

    return parseInt(Math.round(price / 60).toFixed(0));

  }

  function getIsSelectionMade() {

    return (
      selectedModel?.length > 0 ||
      selectedVariant?.length > 0 ||
      selectedYear?.length > 0 ||
      selectedPrice ||
      selectedInstalment ||
      isManualSelected ||
      isAutomaticSelected ||
      isPetrolSelected ||
      isDieselSelected
    );

  }

  return (
    <div className={classes.root}>

      <div className={classes.flexRowContainer}>
        <div className={classes.labelContainer}>
          <p className={classes.label}>Select Your Car</p>
        </div>
      </div>

      <div className={`${classes.flexRowContainer} ${classes.selectorLayoutContainer}`}>

        <div className={classes.selectorGroupContainerOuter}>

          <div className={classes.selectorGroupContainerInner}>

            <div className={classes.selectorContainer}>
              <TextField
                className={classes.selector}
                id="model"
                label={!selectedModel ? "Model" : " "}
                select
                fullWidth
                value={selectedModel}
                onChange={selectModel}
                InputProps={{
                  className: classes.selectorInput,
                  endAdornment: (
                    <Close
                      className={classes.selectorClearIconContainer}
                      onClick={() => setSelectedModel("")}
                      style={{ display: !selectedModel ? "none" : "block" }}
                    />
                  ),
                }}
                SelectProps={{
                  MenuProps: menuProps
                }}
              >
                {
                  models.map((model, i) => (
                    <MenuItem key={i} id={i} value={model} style={{ textTransform: "capitalize" }}>
                      {model}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>


            <div className={classes.verticalDivider}></div>

            <div className={classes.selectorContainer}>
              <TextField
                className={classes.selector}
                id="variant"
                label={!selectedVariant ? "Variant" : " "}
                select
                fullWidth
                value={selectedVariant}
                onChange={selectVariant}
                InputProps={{
                  className: classes.selectorInput,
                  endAdornment: (
                    <Close
                      className={classes.selectorClearIconContainer}
                      onClick={() => setSelectedVariant("")}
                      style={{ display: !selectedVariant ? "none" : "block" }}
                    />
                  ),
                }}
                SelectProps={{
                  MenuProps: menuProps
                }}
              >
                {
                  variants.map((variant, i) => (
                    <MenuItem key={i} id={i} value={variant} style={{ textTransform: "capitalize" }}>
                      {variant}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>

            <div className={classes.verticalDivider}></div>

            <div className={classes.selectorContainer}>
              <TextField
                className={classes.selector}
                id="year"
                label={!selectedYear ? "Year" : " "}
                select
                fullWidth
                value={selectedYear}
                onChange={selectYear}
                InputProps={{
                  className: classes.selectorInput,
                  endAdornment: (
                    <Close
                      className={classes.selectorClearIconContainer}
                      onClick={() => setSelectedYear("")}
                      style={{ display: !selectedYear ? "none" : "block" }}
                    />
                  ),
                }}
                SelectProps={{
                  MenuProps: menuProps
                }}
              >
                {
                  years.map((year, i) => (
                    <MenuItem key={i} id={i} value={year}>
                      {year}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>

          </div>

          <div className={`${classes.verticalDivider} ${classes.verticalDividerConditional}`}></div>

          <div className={classes.selectorGroupContainerInner}>

            <div className={classes.selectorContainer}>
              <TextField
                className={classes.selector}
                id="price"
                label={!selectedPrice ? "Price" : " "}
                select
                fullWidth
                value={selectedPrice}
                onChange={selectPrice}
                InputProps={{
                  className: classes.selectorInput,
                  endAdornment: (
                    <Close
                      className={classes.selectorClearIconContainer}
                      onClick={() => setSelectedPrice("")}
                      style={{ display: !selectedPrice ? "none" : "block" }}
                    />
                  ),
                }}
                SelectProps={{
                  MenuProps: menuProps
                }}
              >
                {
                  prices.map((price, i) => (
                    <MenuItem key={i} id={i} value={price}>
                      R {price.min} - R {price.max}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>

            <div className={classes.verticalDivider}></div>

            <div className={classes.selectorContainer}>
              <TextField
                className={classes.selector}
                id="instalment"
                label={!selectedInstalment ? "Instalment" : " "}
                select
                fullWidth
                value={selectedInstalment}
                onChange={selectInstalment}
                InputProps={{
                  className: classes.selectorInput,
                  endAdornment: (
                    <Close
                      className={classes.selectorClearIconContainer}
                      onClick={() => setSelectedInstalment("")}
                      style={{ display: !selectedInstalment ? "none" : "block" }}
                    />
                  ),
                }}
                SelectProps={{
                  MenuProps: menuProps
                }}
              >
                {
                  instalments.map((instalment, i) => (
                    <MenuItem key={i} id={i} value={instalment}>
                      R {instalment.min} - R {instalment.max}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>

          </div>

        </div>

        <div className={classes.searchButtonContainer}>

          {/* <div className={classes.primaryButton} onClick={search}>
                        <p className={classes.primaryButtonLabel}>Search</p>
                    </div> */}

          <Button
            className={classes.primaryButton}
            onClick={search}
          >
            Search
          </Button>

        </div>

      </div>

      <div className={`${classes.flexRowContainer} ${classes.buttonGroupContainerOuter}`}>

        <div className={`${classes.flexRowContainer} ${classes.buttonGroupContainerInner}`}>

          <Button className={`${classes.transparentButton} ${isManualSelected ? classes.activeButton : ""}`} onClick={toggleManual}>
            Manual
          </Button>

          <Button className={`${classes.transparentButton} ${isAutomaticSelected ? classes.activeButton : ""}`} onClick={toggleAutomatic}>
            Automatic
          </Button>

        </div>

        <div className={`${classes.flexRowContainer} ${classes.buttonGroupContainerInner}`}>

          <Button className={`${classes.transparentButton} ${isPetrolSelected ? classes.activeButton : ""}`} onClick={togglePetrol}>
            Petrol
          </Button>

          <Button className={`${classes.transparentButton} ${isDieselSelected ? classes.activeButton : ""}`} onClick={toggleDiesel}>
            Diesel
          </Button>

        </div>

        <div className={`${classes.flexRowContainer} ${classes.buttonGroupContainerInner}`}>

          <Button className={`${classes.transparentButton} ${isFavouritesSelected ? classes.activeButton : ""}`} onClick={toggleFavourites}>
            Favourites
          </Button>
          {
            filteredVehicles?.length > 0 && allowSort === true &&
            <SortButton
              buttonProps={{
                className: `${classes.transparentButton} ${classes.sortButton}`,
                label: `Sort ${selectedSort.id > 1 ? ` by ${selectedSort.name}` : ""}`
              }}
              menuProps={{
                menuClassName: `${classes.sortMenu}`,
                itemClassName: `${classes.sortItem}`,
                selectedItemClassName: `${classes.sortItemSelected}`
              }}
              menuItems={sortOptions}
              selectItem={sort}
              selectedItemId={selectedSort.id}
            />
          }
        </div>

      </div>

    </div>
  );
};

export default VehicleFilter;