import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: 'black',
    padding: theme.spacing(1, 15, 1),
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '99',
    '@media (max-width: 426px)': {
      padding: theme.spacing(1, 1, 1, 1),
      flexDirection: 'column',
    },
    '@media (min-width: 426px) and (max-width: 1024px)': {
      padding: theme.spacing(1, 1, 1, 1),
      flexDirection: 'column',
    },
  },
  btn: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(1, 5, 1),
    background: '#efdf00',
    color: 'black',
    border: 'none',
    borderRadius: '0px',
    marginRight: '10px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    '@media (max-width: 426px)': {
      margin: theme.spacing(1, 1, 1, 1),
      textAlign: 'center'
    },
    '@media (min-width: 426px) and (max-width: 1024px)': {
      margin: theme.spacing(1, 5, 1, 2),
      textAlign: 'center'
    },
  },
  text: {
    color: 'white',
    margin: theme.spacing(1, 10, 1, 1),
    width: '80%',
    fontSize: '14px',
    '@media (max-width: 426px)': {
      margin: theme.spacing(1, 1, 1, 1),
      textAlign: 'center'
    },
    '@media (min-width: 426px) and (max-width: 1024px)': {
      margin: theme.spacing(1, 5, 1, 2),
      textAlign: 'center'
    },
  },
  buttonHolder: {
    display: 'flex',
    padding: theme.spacing(0, 0, 0),
    height: '100%',
    alignItems: 'center',
    '@media (max-width: 426px)': {
      flexDirection: 'column',
    },
  },
}));

const writeCookie = (name, value, days) => {
  var date, expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString();

  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + ";";

}

const getCookieValue = (a) => {

  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';

};

let Banner;

export default function CookiePopUp() {

  const [hasValue, setHasValue] = useState(false);

  const setCookie = (val) => {
    setHasValue(true);

    if (val) {
      writeCookie('noCookie', true, 1);
    }
    else {
      writeCookie('noCookie', false, 365);
    }
  }

  useEffect(() => {

    if (getCookieValue('noCookie').length > 0) {
      setHasValue(true);
    }

    if (getCookieValue('noCookie').length == 0 || getCookieValue('noCookie') == true) {
      window['ga-disable-UA-141787134-20'] = true;
      window['ga-disable-AW-10801311928'] = true;
    }
    else {
      window['ga-disable-UA-141787134-20'] = false;
      window['ga-disable-AW-10801311928'] = false;

      /*global fbq*/
      /*eslint no-undef: "error"*/
      // fbq('init', '806724033056313');
      // fbq('track', 'PageView');

      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6028ed60b640aa23';
      head.appendChild(script);
    }

  }, []);

  const classes = useStyles();

  if (!hasValue) {
    Banner =
      <div className={classes.background}>
        <p className={classes.text}>
          This site uses cookies and related technologies for site operation, analytics and third party advertising purposes as described in our <Link component={RouterLink} to="/privacy" className={classes.link}>Privacy Policy.</Link> You may choose to consent to our use of these technologies. To opt-out of sharing with third parties information related to these technologies, click "Decline"
        </p>
        <div className={classes.buttonHolder}>
          <button
            onClick={() => setCookie(true)}
            className={classes.btn}>
            DECLINE
          </button>
          <button
            onClick={() => setCookie(false)}
            className={classes.btn}>
            ACCEPT
          </button>
        </div>;
      </div>;
  } else {
    Banner = ""

  }

  return (
    <div>
      {Banner}
    </div>
  )
}