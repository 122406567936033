import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, PAGE_SIZE } from './Constants';
import { DealerContext } from './contexts/DealerContext';

export default function OffersController(pageNumber, sortDirection) {
  let cancel;
  const { loading: loadingDealer, globalDealer } = useContext(DealerContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [offers, setOffers] = useState([]);
  const [hiddenOffers, setHiddenOffers] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (loadingDealer) return;
    setLoading(true);
    setError(false);
    setOffers([]);

    const params = {
      pageNumber,
      pageSize: pageNumber > 0 ? PAGE_SIZE : 1000,
      status: 'active',
      dealerId: globalDealer?.dealerId,
      filterByPackage: true
    };

    axios({
      method: 'GET',
      url: `${BASE_URI}/Offers`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {

      setLoading(false);
      setOffers(response.data?.list?.filter((o) => !o.metaKeywords.toLowerCase().includes("hidden")) || []);
      setHiddenOffers(response.data?.list?.filter((o) => o.metaKeywords.toLowerCase().includes("hidden")) || []);
      setHasMore(response.data?.list?.length > 0);

    }).catch(error => {
      if (axios.isCancel(error)) return;
      setLoading(false);
    });

    return () => cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, sortDirection, loadingDealer])

  return { loading, error, offers, hiddenOffers, hasMore };
};