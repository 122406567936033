import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { currencyFormatter, mileageFormatter, calculateMonthlyInstallment } from "../shared/UtilityFunctions";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { FavouriteContext } from "../shared/contexts/FavouriteContext";
import UsedVehicleFooter from "./UsedVehicleFooter";
import HeroBannerImg from '../shared/assets/New/background_3.png';
import CalculatorImg from '../shared/assets/New/icon_calculator_solid.svg';
import HeartIcon from '../shared/assets/New/icon_heart.png';
import HeartActiveIcon from '../shared/assets/New/icon_heart_filled.png';
import TelephoneIcon from '../shared/assets/New/icon_telephone.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative"
  },
  borderBottomGrey: {
    borderBottom: "1px solid grey"
  },
  borderBottomPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  contentTextLargeBold: {
    fontSize: '22px',
    fontWeight: '600'
  },
  contentText: {
    fontSize: "17px"
  },
  contentTextSmallBold: {
    fontSize: '15px',
    fontWeight: '600',
    "@media (max-width: 550px)": {
      fontSize: "11px",
    },
    "@media (min-width: 551px) and (max-width: 650px)": {
      fontSize: "13px",
    },
    "@media (min-width: 751px) and (max-width: 1000px)": {
      fontSize: "13px"
    }
  },
  contentTextSmall: {
    fontSize: "13px",
    textTransform: "capitalize",
    "@media (max-width: 550px)": {
      fontSize: "9px",
    },
    "@media (min-width: 551px) and (max-width: 650px)": {
      fontSize: "11px",
    },
    "@media (min-width: 751px) and (max-width: 1000px)": {
      fontSize: "11px"
    }
  },
  contentTextVerySmall: {
    fontSize: "10px"
  },
  heroBannerContainerOuter: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    maxHeight: "calc(50vh - 105px)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  heroBannerContainerInner: {
    padding: "20%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      padding: "25px"
    }
  },
  contentContainer: {
    width: "100%",
    padding: "50px 50px",
    paddingTop: "0 !important",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 500px)": {
      padding: "25px 20px"
    },
    "@media (max-width: 750px)": {
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  contentDetail: {
    padding: "10px 0px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      textOverflow: "clip",
      whiteSpace: "nowrap"
    }
  },
  contentDetailGroup: {
    display: "flex",
    alignItems: "center"
  },
  contentDetailIcon: {
    width: "20px",
    marginRight: "5px"
  },
  vehicleInfoContainer: {
    width: "65%",
    "@media (max-width: 750px)": {
      width: "100%"
    }
  },
  vehicleDetailsContainer: {
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  vehicleDetailGroupContainer: {
    width: "calc(100% / 3)"
  },
  vehicleDetailGroupMiddleContainer: {
    width: "calc(100% / 3)",
    borderLeft: "2px solid black",
    borderRight: "2px solid black"
  },
  vehicleGalleryContainer: {
    width: "100%"
  },
  vehicleGalleryImg: {
    width: "100%"
  },
  vehicleGalleryCarouselContainer: {
    width: "100%",
    marginTop: "10px",
    position: "relative",
    overflow: "hidden"
  },
  vehicleGalleryCarousel: {
    display: "flex",
    transform: ({ galleryPos }) => `translate(${galleryPos}%,0%)`
  },
  vehicleGalleryCarouselImageContainer: {
    minWidth: "calc(100% / 6 - 10px)",
    margin: "5px",
    width: "100%",
    cursor: "pointer"
  },
  vehicleGalleryCarouselImg: {
    width: "100%"
  },
  vehicleImagesCarouselBtn: {
    padding: '10px',
    color: 'black',
    opacity: '0.2',
    background: `${theme.palette.secondary.main}`,
    border: 'none',
    position: "absolute",
    top: '50%',
    transform: 'translate(0px, -50%)',
    '&:hover': {
      cursor: "pointer",
      opacity: '0.8',
      background: `${theme.palette.primary.main}`,
    },
  },
  backbtn: {
    left: '0',
  },
  nextbtn: {
    right: '0',
  },
  financeInfoContainer: {
    width: "35%",
    marginLeft: "20px",
    "@media (max-width: 750px)": {
      width: "100%",
      margin: "0",
      marginTop: "30px"
    }
  },
  financeSection: {
    padding: "10px",
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  financeSectionButton: {
    width: "40%",
    minWidth: "150px",
    margin: "5px"
  },
  financeSectionButtonLarge: {
    width: "60%",
    margin: "5px"
  },
  buttonIcon: {
    width: "20px"
  },
  secondaryButton: {
    color: "white",
    border: "none",
    background: theme.palette.secondary.main,
    "&:hover": {
      color: "black",
      background: theme.palette.primary.main,
    }
  },
  legalContainer: {
    width: "100%",
    padding: "50px 50px",
    paddingTop: "0",
    textAlignLast: "center"
  },
}));

const UsedVehicleDetails = () => {
  const [galleryPos, setGalleryPos] = useState(0);
  const history = useHistory();
  const params = useParams();
  const { vehiclesList } = useContext(VehicleContext);
  const { addFavourite, removeFavourite } = useContext(FavouriteContext);
  const classes = useStyles({ galleryPos: galleryPos });
  const [loading, setLoading] = useState(true);
  const [dealer, setDealer] = useState({});
  const [activeVehicle, setActiveVehicle] = useState();
  const [activeGallery, setActiveGallery] = useState([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);
  const [term, setTerm] = useState(60);
  const [interestRate, setInterestRate] = useState(11.75);
  const [depositPerc, setDepositPerc] = useState(10);
  const [balloonPerc, setBalloonPerc] = useState(0);
  const [installment, setInstallment] = useState(0);
  const [tcs, setTCs] = useState("");

  useEffect(() => {
    if (vehiclesList?.length > 0) {
      if (!params?.stockId) {
        console.warn('vehicle not found');
      } else {
        const vehicle = vehiclesList.find(v => v.stockId == params.stockId);
        const tempTerm = vehicle?.term || term;
        const tempRate = vehicle?.linkedInterest || interestRate;
        const tempDepo = vehicle?.deposit || depositPerc;
        const tempBalloon = vehicle?.balloon || balloonPerc;
        setActiveVehicle(vehicle);
        getVehicleImagesGallery();
        getDealer(vehicle.dealerId);
        setTerm(tempTerm);
        setInterestRate(tempRate);
        setDepositPerc(tempDepo);
        setBalloonPerc(tempBalloon);
        setInstallment(calculateMonthlyInstallment(vehicle?.price, tempDepo, tempBalloon, tempRate, tempTerm));
        setTCs(
          vehicle?.termsAndConditions ||
          `While we make every attempt to give you the best possible tools and information, Renault Selection, its agents, employees and accredited 
                    financiers will accept no responsibility for any loss or miscalculations that may arise. All calculations made on this calculator are to 
                    be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other 
                    factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. 
                    Please not that by default this calculator uses the prime interest rate +2% and a ${tempTerm}-month loan repayment term to calculate 
                    estimated monthly repayment.`
        );
      }
    }
  }, [vehiclesList]);

  const getVehicleImagesGallery = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URI}/stock/${params?.stockId}`,
      });
      setActiveGallery(response?.data?.mainUrls);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) return;
    }
  };

  const getDealer = async (dealerId) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URI}/dealers/${dealerId}`,
      });
      setDealer(response?.data);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) return;
    }
  };

  const handleGalleryNext = () => {
    if (Math.abs(galleryPos) < Math.abs(((activeGallery.length - 1) / 2) * 16.66)) {
      setGalleryPos(galleryPos - 16);
    }
  };

  const handleGalleryBack = () => {
    if (galleryPos < 0) {
      setTimeout(() => {
        setGalleryPos(galleryPos + 16);
      }, 100);
    }
  };

  const handleGalleryIndex = (index) => {
    setActiveGalleryIndex(index);
  };

  const enquire = () => {
    history.push("/contact-us", { stockId: activeVehicle?.stockId });
  };

  const toggleFavourite = () => {
    if (activeVehicle?.isFavourite === true) {
      removeFavourite(activeVehicle);
    } else {
      addFavourite(activeVehicle);
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.heroBannerContainerOuter}
        style={{ backgroundImage: `linear-gradient( rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.2) ), url(${HeroBannerImg})` }}
      >
        <div className={classes.heroBannerContainerInner}>
          <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
            view our
          </Typography>
          <Typography variant='h5' gutterBottom className={`${classes.sectionHeadingSmall} ${classes.borderBottomPrimary}`}>
            Vehicle's Detail Information
          </Typography>
        </div>
      </div>
      <div className={classes.contentTitleContainer}>
        {
          loading === true
            ? <CircularProgress size={100} />
            : <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey" }}>
              {Object.keys(activeVehicle || {}).length > 0 ? "More about this vehicle" : "No details available"}
            </Typography>
        }
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.vehicleInfoContainer}>
          <div className={classes.vehicleDetailsContainer}>
            <div className={classes.vehicleDetailGroupContainer} style={{ paddingRight: "20px" }}>
              <div className={classes.contentDetail}>
                <Typography className={classes.contentTextSmallBold}>
                  Year
                </Typography>

                <Typography className={classes.contentTextSmall}>
                  {activeVehicle?.year}
                </Typography>
              </div>
              <div className={classes.contentDetail}>
                <Typography className={classes.contentTextSmallBold}>
                  Mileage
                </Typography>

                <Typography className={classes.contentTextSmall}>
                  {mileageFormatter.format(activeVehicle?.mileage)}
                </Typography>
              </div>
            </div>
            <div className={classes.vehicleDetailGroupMiddleContainer} style={{ padding: "0 20px" }}>
              <div className={classes.contentDetail}>
                <Typography className={classes.contentTextSmallBold}>
                  Fuel type
                </Typography>

                <Typography className={classes.contentTextSmall}>
                  {activeVehicle?.fuelType ? activeVehicle?.fuelType?.trim() : 'N/A'}
                </Typography>
              </div>
              <div className={classes.contentDetail}>
                <Typography className={classes.contentTextSmallBold}>
                  Transmission
                </Typography>

                <Typography className={classes.contentTextSmall}>
                  {activeVehicle?.transmission ? activeVehicle?.transmission : 'N/A'}
                </Typography>
              </div>
            </div>
            <div className={classes.vehicleDetailGroupContainer} style={{ paddingLeft: "20px" }}>
              <div className={classes.contentDetail}>
                <Typography className={classes.contentTextSmallBold}>
                  Colour
                </Typography>

                <Typography className={classes.contentTextSmall}>
                  {activeVehicle?.colour}
                </Typography>
              </div>
              <div className={classes.contentDetail}>
                <Typography className={classes.contentTextSmallBold}>
                  Stock No #
                </Typography>

                <Typography className={classes.contentTextSmall} style={{ textTransform: "uppercase" }}>
                  {activeVehicle?.stockNo}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.vehicleGalleryContainer}>
            <img className={classes.vehicleGalleryImg} src={activeGallery[activeGalleryIndex]} />
            <div className={classes.vehicleGalleryCarouselContainer}>
              <div className={classes.vehicleGalleryCarousel}>
                {
                  activeGallery?.map((image, index) =>
                    <div className={classes.vehicleGalleryCarouselImageContainer} onClick={() => { handleGalleryIndex(index) }}>
                      <img className={classes.vehicleGalleryCarouselImg} src={image} />
                    </div>
                  )
                }
              </div>
              <button className={`${classes.vehicleImagesCarouselBtn} ${classes.backbtn}`} onClick={handleGalleryBack}>
                <ArrowBackIosNewIcon />
              </button>
              <button className={`${classes.vehicleImagesCarouselBtn} ${classes.nextbtn}`} onClick={handleGalleryNext}>
                <ArrowForwardIosIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={classes.financeInfoContainer}>
          <div className={classes.financeSection}>
            <Typography className={classes.contentText}>{activeVehicle?.year} {activeVehicle?.brand} {activeVehicle?.model}</Typography>
            <Typography className={classes.contentTextSmall}>{activeVehicle?.modelRange}</Typography>
          </div>
          <div className={classes.financeSection}>
            <Typography className={classes.contentTextLargeBold}>
              {currencyFormatter.format(activeVehicle?.price)}
            </Typography>
            <div className={classes.contentDetail}>
              <Typography className={classes.contentTextSmall} style={{ textTransform: "unset", margin: "5px 0" }}>
                From {currencyFormatter.format(installment)} pm
              </Typography>
              <div className={classes.contentDetailGroup}>
                <img className={classes.contentDetailIcon} src={CalculatorImg} />
                <Typography className={classes.contentTextSmallBold}>
                  Finance Calculator
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.financeSection}>
            <div className={classes.contentDetail} style={{ justifyContent: "center" }}>
              <Button className={`${classes.financeSectionButton} ${classes.secondaryButton}`} onClick={enquire}>
                enquire now
              </Button>
            </div>
          </div>
          <div className={classes.financeSection}>
            <Typography className={`${classes.contentTextLargeBold} ${classes.borderBottomGrey}`} style={{ paddingBottom: "10px" }}>
              Quick Summary
            </Typography>
            <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
              <Typography className={classes.contentTextSmallBold}>
                Purchase Price
              </Typography>
              <Typography className={classes.contentTextSmall}>
                {currencyFormatter.format(activeVehicle?.price)}
              </Typography>
            </div>
            <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
              <Typography className={classes.contentTextSmallBold}>
                Monthly Installment
              </Typography>
              <Typography className={classes.contentTextSmall}>
                {currencyFormatter.format(installment)}
              </Typography>
            </div>
            <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
              <Typography className={classes.contentTextSmallBold}>
                Term (Months)
              </Typography>
              <Typography className={classes.contentTextSmall}>
                {activeVehicle?.term || term}
              </Typography>
            </div>
            <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
              <Typography className={classes.contentTextSmallBold}>
                Linked Interest Rate
              </Typography>
              <Typography className={classes.contentTextSmall}>
                {activeVehicle?.linkedInterest || interestRate} %
              </Typography>
            </div>
            <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
              <Typography className={classes.contentTextSmallBold}>
                Deposit
              </Typography>
              <Typography className={classes.contentTextSmall}>
                {activeVehicle?.deposit || depositPerc} %
              </Typography>
            </div>
            <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
              <Typography className={classes.contentTextSmallBold}>
                Balloon Payment
              </Typography>
              <Typography className={classes.contentTextSmall}>
                {activeVehicle?.balloon || balloonPerc} %
              </Typography>
            </div>
          </div>
          <div className={classes.financeSection}>
            <div className={classes.contentDetail} style={{ flexDirection: "column" }}>
              <Button
                className={`${classes.financeSectionButtonLarge} ${classes.secondaryButton}`}
                onClick={toggleFavourite}
                endIcon={<img className={classes.buttonIcon} src={activeVehicle?.isFavourite === true ? HeartActiveIcon : HeartIcon} />}
              >
                favourites
              </Button>
              <Typography className={classes.contentTextVerySmall}>
                {dealer?.address?.postalAddress || dealer?.address?.streetAddress}
              </Typography>
              <div className={classes.contentDetailGroup}>
                <img className={classes.contentDetailIcon} src={TelephoneIcon} />
                <Typography className={classes.contentTextVerySmall}>
                  {dealer?.usedCarsPhoneNumber || dealer?.newCarsPhoneNumber}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.legalContainer} ${classes.borderBottomGrey}`}>
        <Typography className={classes.contentTextSmallBold} gutterBottom>
          Disclaimer*
        </Typography>
        <Typography className={classes.contentTextVerySmall}>
          {tcs}
        </Typography>
      </div>
      <UsedVehicleFooter />
    </div>
  );
};

export default UsedVehicleDetails;