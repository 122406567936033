import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, MOTORGROUPID, MASTER_DEALER, MASTER_DEALERID } from '../Constants'
import { cleanDealerName } from '../UtilityFunctions';

export const DealerContext = createContext();

export const DealerProvider = ({ children }) => {

  let cancel;

  const [loading, setLoading] = useState(true);
  const [dealers, setDealers] = useState();
  const [globalDealer, setGlobalDealer] = useState();
  const [websiteColors, setWebsiteColors] = useState();

  useEffect(() => {
    Promise.allSettled([
      getDealers()
    ]).then(() => {

      setLoading(false);

    }).catch(e => console.warn('Promise.all.error', e));

    return () => cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (dealers?.length > 0) {
      const paths = window.location.pathname.split("/");
      if (paths[1] === "dealer") {
        const dealerName = paths[2];
        const pathDealer = dealers.find(d => cleanDealerName(d.name) === dealerName);

        assignDealer(pathDealer?.dealerId || MASTER_DEALERID);
      } else {
        assignDealer(MASTER_DEALERID);
      }
    };

  }, [dealers]);

  const getDealers = async () => {

    try {

      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`,
        cancelToken: new axios.CancelToken(c => cancel = c)
      });

      let tempDealers = [];

      if (!response?.data || response.data.length <= 0) {

        tempDealers = [MASTER_DEALER];

      } else if (MOTORGROUPID === 0) {

        let filteredDealers = response.data.filter(d => d.dealerId == MASTER_DEALERID);
        tempDealers = filteredDealers || [MASTER_DEALER];

      } else {

        tempDealers = response.data || [MASTER_DEALER];

      }

      for (let index = 0; index < tempDealers.length; index++) {
        const element = tempDealers[index];

        if (element.name.includes("Selection ")) {
          element.name = element.name.replace("Selection ", '')
        }
      }

      let filtered = tempDealers.sort((a, b) => a?.name > b?.name ? 1 : -1)

      setDealers(filtered);

    } catch (error) {
      if (axios.isCancel(error)) return;
    }

  };

  const assignDealer = (dealerId) => {
    let dealer = dealers.find(d => d.dealerId == dealerId);
    if (dealer) {
      setGlobalDealer(dealer);
      setWebsiteColors(dealer.websiteColors);
    } else {
      setGlobalDealer(MASTER_DEALER);
      setWebsiteColors(MASTER_DEALER.websiteColors);
    }
  };

  return (
    <DealerContext.Provider value={{ loading, globalDealer, websiteColors, dealers, assignDealer }}>
      {children}
    </DealerContext.Provider>
  )
};