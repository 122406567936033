import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from '@mui/material/Slider';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: 'black',
    padding: theme.spacing(1, 15, 1),
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '99',
    '@media (max-width: 426px)': {
      padding: theme.spacing(1, 1, 1, 1),
      flexDirection: 'column',
    },
    '@media (min-width: 426px) and (max-width: 1024px)': {
      padding: theme.spacing(1, 1, 1, 1),
      flexDirection: 'column',
    },
  },
  holder: {
    width: "75%",

    background: 'white',
    margin: '0 auto',
  },
  answerHolder: {
    width: "100%",
    padding: "20px",
    background: 'white',
    textAlign: 'right',
    fontSize: '1.2rem',
    background: '#f9fafb'
  },
  header: {
    display: 'flex',
    padding: "10px 10px",
    justifyContent: 'space-between',
    background: theme.palette.secondary.main,
    marginBottom: '15px',
    textTransform: 'initial!important'
  },
  headerTitle: {
    color: "white",
    textTransform: "uppercase",
    fontSize: "19px",
    fontWeight: "600",
    "@media (max-width: 400px)": {
      fontSize: "13px",
    },
    "@media (min-width: 401px) and (max-width: 600px)": {
      fontSize: "15px",
    },
    "@media (min-width: 601px) and (max-width: 800px)": {
      fontSize: "17px"
    }
  },
  input: {
    padding: "5px 10px",
  },
  close: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    }
  },
  slider: {
    color: `${theme.palette.primary.main} !important`
  }
}));

export default function FinanceCalulator(props) {

  const Open = () => {
    props.openCalc(false)

  }

  const classes = useStyles();
  const periodList = [84, 72, 60, 48, 36,];
  const [amount, setAmount] = useState("");
  const [period, setPeriod] = useState(periodList[2]);
  const [balloon, setBalloon] = useState("");
  const [balloonPercent, setBalloonPercent] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [depositPercent, setDepositPercent] = useState("");
  const [interestRatePercent, setInterestRatePercent] = useState(7);
  const [totalAmount, setTotalAmount] = useState();

  const values =
  {
    amount: `${amount}`,
    period: `${period}`,
    balloon: `${balloon}`,
    balloonPercent: `${balloonPercent}`,
    depositAmount: `${depositAmount}`,
    depositPercent: `${depositPercent}`,
    interestRatePercent: `${interestRatePercent}`,
  };

  const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setPeriod(e.target.value)
    setFieldValue(fieldName, e.target.value)
  }

  const handleAmountChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setAmount(e.target.value)
    setFieldValue(fieldName, e.target.value)
  }

  const handleInterestChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setInterestRatePercent(e.target.value)
    setFieldValue(fieldName, e.target.value)
  }


  const handleBalloonChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setBalloon(e.target.value)
    setBalloonPercent(e.target.value / values.amount * 100)
  }

  const handleDepositChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setDepositAmount(e.target.value)
    setDepositPercent(e.target.value / values.amount * 100)
  }

  const handleBalloonPercent = (e, value, setFieldValue, fieldName, fieldId) => {
    setBalloonPercent(e.target.value)
    setFieldValue(fieldName, e.target.value)

    setBalloon(values.amount * e.target.value / 100)
    setFieldValue("balloon", values.amount * e.target.value / 100)
  }
  const handleDepositPercent = (e, value, setFieldValue, fieldName, fieldId) => {
    setDepositPercent(e.target.value)
    setFieldValue(fieldName, e.target.value)

    setDepositAmount(values.amount * e.target.value / 100)
    setFieldValue("depositAmount", values.amount * e.target.value / 100)
  }

  useEffect(() => {
    let sum = values.amount
    var interestPM = interestRatePercent / 100 / 12;
    let months = values.period
    let Total = ""
    if (values.amount !== "" && balloon < 0) {

      Total = (sum - depositAmount) * interestPM * (Math.pow(1 + interestPM, (months))) / (Math.pow(1 + interestPM, (months)) - 1);
      let installmentPM = Math.round(Total)
      setTotalAmount(installmentPM.toFixed(0))

    } else {

      Total = (sum - depositAmount - balloon) * interestPM * (Math.pow(1 + interestPM, (months))) / (Math.pow(1 + interestPM, (months)) - 1);
      let installmentPM = Math.round(Total)
      setTotalAmount(installmentPM.toFixed(0))
    }
  }, [values])

  return (
    <div>
      <Formik
        isInitialValid={false}
        initialValues={values}
        enableReinitialize={true}
      >
        {(props) => {
          let {
            values: {
              amount,
              period,
              balloon,
              balloonPercent,
              depositAmount,
              depositPercent,
              interestRatePercent,

            },
            touched,
            errors,
            isValid,
            submitForm,
            handleBlur,
            setFieldTouched,
            setFieldValue,

          } = props;
          return (

            <Form className={classes.holder}>

              <div className={classes.header}>
                <Typography gutterBottom className={`${classes.headerTitle}`}>
                  finance calculator
                </Typography>
                <Typography gutterBottom className={`${classes.headerTitle} ${classes.close}`} onClick={() => { Open() }}>
                  X
                </Typography>
              </div>
              <Grid item className={classes.input} xs={12} md={12}
                lg={12}>
                <TextField
                  id="amount"
                  name="amount"
                  type="text"
                  label="Loan Amount"
                  fullWidth
                  value={amount}
                  variant="outlined"
                  helperText={touched.amount ? errors.amount : ""}
                  error={touched.amount && Boolean(errors.amount)}
                  onChange={(e, child) => handleAmountChange(e, child, setFieldValue, 'amount', 'amount')}
                />
              </Grid>

              <Grid item className={classes.input} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="period"
                  select
                  label="Period"
                  value={period}
                  helperText={errors.period && touched.period ? errors.period : ''}
                  error={errors.period && touched.period}
                  onBlur={handleBlur("dealerId")}
                  onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'period', 'period')}
                >
                  {periodList.map((period) => (
                    <MenuItem key={period.id} id={period} value={period}>
                      {period} Months
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid container spacing={2} className={classes.input}>
                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                  lg={6}>
                  <TextField
                    id="balloon"
                    name="balloon"
                    type="text"
                    label="Balloon Amount"
                    fullWidth
                    value={balloon}
                    variant="outlined"
                    helperText={touched.balloon ? errors.balloon : ""}
                    error={touched.balloon && Boolean(errors.balloon)}
                    onChange={(e, child) => handleBalloonChange(e, child, setFieldValue, 'balloon', 'balloon')}
                  />
                </Grid>
                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                  lg={6}>
                  <TextField
                    id=" balloonPercent"
                    name="balloonPercent"
                    type="text"
                    label="Balloon (%)"
                    fullWidth
                    value={balloonPercent}
                    variant="outlined"
                    helperText={touched.balloonPercent ? errors.balloonPercent : ""}
                    error={touched.balloonPercent && Boolean(errors.balloonPercent)}
                    onChange={(e, child) => handleBalloonPercent(e, child, setFieldValue, 'balloonPercent', 'balloonPercent')}

                  />
                </Grid>
                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                  lg={6}>
                  <TextField
                    id=" depositAmount"
                    name="depositAmount"
                    type="text"
                    label="Deposit Amount"
                    fullWidth
                    value={depositAmount}
                    variant="outlined"
                    helperText={touched.depositAmount ? errors.depositAmount : ""}
                    error={touched.depositAmount && Boolean(errors.depositAmount)}
                    onChange={(e, child) => handleDepositChange(e, child, setFieldValue, 'depositAmount', 'depositAmount')}
                  />
                </Grid>
                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                  lg={6}>
                  <TextField
                    id=" depositPercent"
                    name="depositPercent"
                    type="text"
                    label="Deposit (%)"
                    fullWidth
                    value={depositPercent}
                    variant="outlined"
                    helperText={touched.depositPercent ? errors.depositPercent : ""}
                    error={touched.depositPercent && Boolean(errors.depositPercent)}
                    onChange={(e, child) => handleDepositPercent(e, child, setFieldValue, 'depositPercent', 'depositPercent')}
                  />
                </Grid>
              </Grid>
              <Grid item className={classes.input} xs={12} md={12}
                lg={12}>
                Interest Rate (%)
                <Slider
                  className={classes.slider}
                  aria-label="Temperature"
                  defaultValue={interestRatePercent}
                  valueLabelDisplay="auto"
                  step={0.5}
                  marks
                  min={7}
                  max={14}
                  onChange={(e, child) => handleInterestChange(e, child, setFieldValue, 'interestRatePercent', 'interestRatePercent')}
                />
              </Grid>
              <div className={classes.answerHolder}>
                Monthly Installment: R{totalAmount}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  )
}