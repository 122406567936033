import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Button, Typography } from "@material-ui/core";
import { FavouriteContext } from "../shared/contexts/FavouriteContext";
import { currencyFormatter, mileageFormatter, calculateMonthlyInstallment } from "../shared/UtilityFunctions";
import HeroBannerImg from '../shared/assets/New/background_4.png';
import ComingSoon from '../shared/assets/New/vehicle_card_default.png';
import HeartIcon from '../shared/assets/New/icon_heart.png';
import HeartActiveIcon from '../shared/assets/New/icon_heart_filled.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative"
  },
  borderBottomPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  heroBannerContainerOuter: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    maxHeight: "calc(50vh - 105px)",
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroBannerContainerInner: {
    padding: "20%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    justifyContent: "center"
  },
  vehiclesContainer: {
    width: "100%",
    padding: "50px 20px",
    paddingTop: "0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around"
  },
  vehicleCard: {
    width: "calc(100% / 3 - 20px )",
    margin: "20px auto",
    padding: "15px",
    boxShadow: `0px 3px 5px 0px ${theme.palette.secondary.main}`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    "@media (max-width: 500px)": {
      width: "100%",
    },
    "@media (min-width: 501px) and (max-width: 700px)": {
      width: "80%",
    },
    "@media (min-width: 701px) and (max-width: 999px)": {
      width: "calc(100% / 2 - 20px )",
    },
  },
  cardImgContainer: {
    width: "100%",
    position: "relative"
  },
  cardImg: {
    width: "100%"
  },
  favouriteIconContainer: {
    width: "25px",
    height: "25px",
    margin: "10px",
    position: "absolute",
    zIndex: 10,
    top: 0,
    right: 0,
    "&:hover": {
      cursor: "pointer",
      "& $favouriteIcon": {
        display: "none"
      },
      "& $favouriteIconActive": {
        display: "block"
      }
    }
  },
  favouriteIcon: {
    width: "100%",
  },
  favouriteIconActive: {
    display: "none"
  },
  cardDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px"
  },
  cardTitleContainer: {
    marginBottom: '10px'
  },
  cardTitle: {
    fontSize: '1.2em',
    fontWeight: '600',
    textTransform: "uppercase"
  },
  cardTitleDescription: {
    fontSize: "0.8em"
  },
  cardPriceContainer: {
    marginBottom: '10px'
  },
  cardPrice: {
    fontSize: '1.8em',
    fontWeight: '600'
  },
  cardInstallment: {
    fontSize: "0.8em"
  },
  cardTextGroupContainer: {
    width: "100%",
    display: 'flex',
    alignItems: "center",
    margin: '5px 0px'
  },
  cardDetailIcon: {
    width: "20px",
    marginRight: "20px"
  },
  cardDetail: {
    fontSize: "0.8em",
    textTransform: "capitalize"
  },
  cardDetailBold: {
    fontWeight: "600"
  },
  cardButtonGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    marginTop: "20px"
  },
  cardButton: {
    width: "48%",
  },
  secondaryButton: {
    color: "white",
    border: "none",
    background: theme.palette.secondary.main,
    "&:hover": {
      color: "black",
      background: theme.palette.primary.main,
    }
  },
  transparentButton: {
    background: "transparent",
    border: "1px solid black",
    "&:hover": {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  }
}));

const Favourite = () => {

  const classes = useStyles();
  const history = useHistory();

  const { favourites, removeFavourite } = useContext(FavouriteContext);

  const navigateEnquire = (stockId) => {

    history.push("/contact-us", { stockId: stockId });

  };

  const navigateDetail = (stockId) => {

    history.push(`/pre-loved/${stockId}`);

  };

  return (
    <div className={classes.root}>

      <div
        className={classes.heroBannerContainerOuter}
        style={{ backgroundImage: `linear-gradient( rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.2) ), url(${HeroBannerImg})` }}
      >
        <div className={classes.heroBannerContainerInner}>
          <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
            your selected
          </Typography>
          <Typography variant='h5' gutterBottom className={`${classes.sectionHeadingSmall} ${classes.borderBottomPrimary}`}>
            Favourites
          </Typography>
        </div>
      </div>

      <div className={classes.contentTitleContainer}>
        <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey" }}>
          {favourites?.length > 0 ? "View your favourites" : "No favourites selected yet"}
        </Typography>
      </div>

      {
        favourites?.length > 0 &&
        <div className={classes.vehiclesContainer}>
          {
            favourites.map((v, index) => (
              <div key={index} className={classes.vehicleCard} >

                <div className={classes.cardImgContainer}>

                  <img className={classes.cardImg} src={v?.image || ComingSoon} />

                  <div className={classes.favouriteIconContainer} onClick={() => removeFavourite(v)}>
                    <img className={classes.favouriteIcon} src={v.isFavourite === true ? HeartActiveIcon : HeartIcon} />
                    <img className={`${classes.favouriteIcon} ${classes.favouriteIconActive}`} src={v.isFavourite === true ? HeartIcon : HeartActiveIcon} />
                  </div>

                </div>

                <div className={classes.cardDetailsContainer}>

                  <div className={classes.cardTitleContainer}>
                    <Typography className={classes.cardTitle}>{v?.year} {v?.brand} {v?.model}</Typography>
                    <Typography className={classes.cardTitleDescription}>{v?.modelRange}</Typography>
                  </div>

                  <div className={classes.cardPriceContainer}>
                    <Typography className={classes.cardPrice}>{currencyFormatter.format(v?.price)}</Typography>
                    <Typography className={classes.cardInstallment}>
                      {currencyFormatter.format(calculateMonthlyInstallment(v?.price, 10, 0, 9, 60))}&nbsp;
                      pm | 10% Deposit | Term 60 Months | 9% Interest
                    </Typography>
                  </div>

                  <div className={classes.cardTextGroupContainer}>
                    <img className={classes.cardDetailIcon} src={HeartActiveIcon} />
                    <Typography className={classes.cardDetail}>
                      Odometer:&nbsp;
                      {mileageFormatter.format(v?.mileage)}
                    </Typography>
                  </div>

                  <div className={classes.cardTextGroupContainer}>
                    <img className={classes.cardDetailIcon} src={HeartActiveIcon} />
                    <Typography className={classes.cardDetail}>
                      Transmission:&nbsp;
                      {v?.transmission}
                    </Typography>
                  </div>

                  <div className={classes.cardTextGroupContainer}>
                    <img className={classes.cardDetailIcon} src={HeartActiveIcon} />
                    <Typography className={classes.cardDetail}>
                      Condition:&nbsp;
                      {v?.newUsed}
                    </Typography>
                  </div>

                  <div className={classes.cardTextGroupContainer}>
                    <img className={classes.cardDetailIcon} src={HeartActiveIcon} />
                    <Typography className={classes.cardDetail}>
                      Fuel Type:&nbsp;
                      {v?.fuelType?.trim()?.toLowerCase()?.startsWith("p") ? "Petrol" : "Diesel"}
                    </Typography>
                  </div>

                  <div className={classes.cardTextGroupContainer}>
                    <img className={classes.cardDetailIcon} src={HeartActiveIcon} />
                    <Typography className={classes.cardDetail}>
                      Balance of Manufacturer Warranty*
                    </Typography>
                  </div>

                  <div className={classes.cardTextGroupContainer}>
                    <img className={classes.cardDetailIcon} src={HeartActiveIcon} />
                    <Typography className={`${classes.cardDetail} ${classes.cardDetailBold}`}>
                      Location:&nbsp;
                      {v?.dealershipName}
                    </Typography>
                  </div>

                  <div className={classes.cardButtonGroupContainer}>

                    <Button className={`${classes.cardButton} ${classes.transparentButton}`} onClick={() => { navigateEnquire(v?.stockId) }}>
                      enquire now
                    </Button>

                    <Button className={`${classes.cardButton} ${classes.secondaryButton}`} onClick={() => { navigateDetail(v?.stockId) }}>
                      view details
                    </Button>

                  </div>

                </div>

              </div>
            ))
          }
        </div>
      }

    </div>
  );
};

export default Favourite;
