import React, { useState, useContext, createRef } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  Link,
  TextField,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { FavouriteContext } from './shared/contexts/FavouriteContext';
import Logo from './shared/assets/logo.png';
import SearchIcon from './shared/assets/New/icon_search.png';
import LocationIcon from './shared/assets/New/icon_location.png';
import HeartIcon from './shared/assets/New/icon_heart.png';

const drawerWidth = 240;

const navBarStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "space-between"
    },
  },
  logoImg: {
    height: 'auto',
    width: '60px',
    maxWidth: '60px'
  },
  menuItemsContainer: {
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      alignItems: "flex-start"
    },
  },
  menuItem: {
    padding: '10px !important',
    cursor: 'pointer',
    color: 'white',
    fontSize: "1rem",
    textTransform: "uppercase",
    textAlign: "center",
    '&:hover': {
      textDecoration: 'none',
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    "@media (max-width: 400px)": {
      fontSize: "0.8rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.9rem",
    }
  },
  menuItemActive: {
    textDecoration: 'none',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  iconsContainer: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
    justifyContent: "flex-end",
    '@media (max-width: 600px)': { //'@media (max-width: 1024px)': {
      display: 'none'
    },
  },
  searchBar: {
    maxWidth: 0,
    background: "white",
    transition: "max-width 0.5s ease-in"
  },
  iconImg: {
    cursor: "pointer",
    margin: '10px',
    '&:hover': {
      color: 'white',
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    }
  },
  badge: {
    background: theme.palette.primary.main,
    color: 'white',
    padding: '0px',
    borderRadius: '50%',
    width: "20px",
    height: '20px',
    display: 'block',
    textAlign: 'center',
    position: 'absolute',
    fontSize: '0.8rem'
  },
  badgeImg: {
    width: "30px",
    margin: '0 auto',
    display: 'block'
  },
  badgeText: {
    color: 'white',
    display: 'block',
    textAlign: 'center',
    fontSize: "11px"
  },
  drawerContainer: {
    flexGrow: "1",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.secondary.main,
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  }
}));

export default function NavbarComponent() {
  const classes = navBarStyles();
  const history = useHistory();
  const { favourites } = useContext(FavouriteContext)
  const searchFieldRef = createRef();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleSearchField = () => {
    if (!isSearching) {
      searchFieldRef.current.focus();
    }
    setIsSearching(!isSearching);
  };

  const search = (e) => {
    if (Object.keys(e || {}).includes("keyCode")) {
      if (e.keyCode === 13) {
        setSearchField("");
        setIsSearching(false);
        history.push(`/pre-loved/search/${searchField}`)
      }
    }
  };

  const menuItems = [
    (
      <Link
        key='menu1'
        className={classes.menuItem}
        activeClassName={classes.menuItemActive}
        onClick={() => setMobileOpen(false)}
        component={RouterLink} to='/pre-loved'
      >
        Pre-Loved
      </Link>
    ),
    // (
    //     <Link 
    //         key='menu2' 
    //         className={classes.menuItem}
    //         activeClassName={classes.menuItemActive}
    //         onClick={() => setMobileOpen(false)}
    //         component={RouterLink} to='/approved-benefits'
    //     >
    //         Approved Benefits
    //     </Link>
    // ),
    // (
    //     <Link 
    //         key='menu4' 
    //         className={classes.menuItem}
    //         activeClassName={classes.menuItemActive}
    //         onClick={() => setMobileOpen(false)}
    //         component={RouterLink} to='/our-dealers'
    //     >
    //         Our Dealers
    //     </Link>
    // ),
    (
      <Link
        key='menu5'
        className={classes.menuItem}
        activeClassName={classes.menuItemActive}
        onClick={() => setMobileOpen(false)}
        component={RouterLink} to='/special-offers'
      >
        Special Offers
      </Link>
    )
  ];

  return (
    <AppBar position="static" color="secondary" elevation={0}>
      <Toolbar disableGutters className={classes.root}>
        <Link component={RouterLink} to='/' >
          <img className={classes.logoImg} alt="Renault Virtual" src={Logo} />
        </Link>
        {/* Menu items for large screens */}
        <Hidden xsDown implementation="css">
          <Toolbar className={classes.menuItemsContainer}>
            {
              menuItems.map(m => (m))
            }
          </Toolbar>
        </Hidden>
        {/* Menu items for mobile screens */}
        <Hidden smUp implementation="css">
          {/* Burger icon */}
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor='right'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            <IconButton className={classes.closeMenuButton} onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
            <div className={classes.menuItemsContainer}>
              {
                menuItems.map(m => (m))
              }
              <Link
                key='menu7'
                className={classes.menuItem}
                activeClassName={classes.menuItemActive}
                onClick={() => setMobileOpen(false)}
                component={RouterLink} to='/my-favourites'
              >
                my favourites
              </Link>
            </div>
          </Drawer>
        </Hidden>
        {/* Function icons for larger screens >1024px  */}
        <Box className={classes.iconsContainer}>
          <TextField
            className={classes.searchBar}
            id="searchField"
            fullWidth
            variant="outlined"
            placeholder='Search models'
            inputRef={searchFieldRef}
            value={searchField}
            onKeyUp={search}
            onChange={(e) => { setSearchField(e?.target?.value) }}
            style={{ maxWidth: isSearching === true ? "500px" : "0" }}
            inputProps={{
              style: {
                padding: "10px 5px",
                fontSize: "12px"
              },
            }}
          />
          <div
            className={`${classes.iconImg} ${isSearching ? classes.menuItemActive : ""}`}
            onClick={toggleSearchField}
          >
            <img className={classes.badgeImg} src={SearchIcon} />
            <span className={classes.badgeText}>search</span>
          </div>
          <Link color="textSecondary" component={RouterLink} to='/our-dealers'
            activeClassName={classes.menuItemActive}
            className={classes.iconImg}
          >
            <div>
              <img className={classes.badgeImg} src={LocationIcon} />
              <span className={classes.badgeText}>find a dealer</span>
            </div>
          </Link>
          <Link color="textSecondary" component={RouterLink} to='/my-favourites'
            activeClassName={classes.menuItemActive}
            className={classes.iconImg}
          >
            <div>
              {favourites.length > 0 && <span className={classes.badge}>{favourites.length}</span>}
              <img className={classes.badgeImg} src={HeartIcon} />
              <span className={classes.badgeText}>my favorites</span>
            </div>
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
