import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

////////////////
import { Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { DealerContext } from '../shared/contexts/DealerContext';
import { VehicleContext } from "../shared/contexts/VehicleContext";
import OffersController from '../shared/OffersController';
import VehicleFilterBanner from "../shared/vehicleFilter/VehicleFilterBanner";
import CarouselShared from '../shared/Carousel';
import HeroBannerImg from '../shared/assets/New/background_1.png';
import JourneyImg1 from '../shared/assets/New/journey_1.png';
import JourneyImg2 from '../shared/assets/New/journey_2.png';
import JourneyImg3 from '../shared/assets/New/journey_3.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative"
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionTextContainer: {
    padding: "10px 10%",
  },
  sectionText: {
    fontSize: "1.2rem"
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "none",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  heroBannerContainerOuter: {
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    backgroundPosition: "top center",
    "@media (min-width: 950px)": {
      maxHeight: "calc(50vh - 105px)",
    },
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroBannerContainerInner: {
    padding: "20%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  filterContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  preLovedContainer: {
    width: "100%",
    padding: "50px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  offersCarouselContainer: {
    width: "100%",
  },
  infoBlocksContainer: {
    width: "100%",
    padding: "50px 10px",
    paddingTop: "0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  infoBlockContainer: {
    width: "22%",
    minWidth: "150px",
    maxHeight: "400px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  iconContainer: {
    //width: "100%"
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    width: "100%",
    maxWidth: "50px"
  },
  infoBlockHeading: {
    fontSize: "1.1rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "0.9rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1rem",
    }
  },
  infoBlockText: {
    fontSize: "0.8rem",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.7rem",
    }
  },
  actionBlocksSectionContainer: {
    width: "100%",
    padding: "50px 10px",
    paddingTop: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  actionBlocksContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  actionBlockContainer: {
    width: "30%",
    minWidth: "250px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  actionBlockImgContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden"
  },
  actionBlockImg: {
    width: "100%",
    border: "none",
    borderRadius: "5px"
  },
  actionBlockSlogan: {
    fontSize: "0.7rem",
    paddingTop: "20px",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.5rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.6rem",
    }
  },
  actionBlockHeading: {
    fontSize: "0.9rem",
    fontWeight: "600",
    padding: "15px 0",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.7rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  actionBlockDescription: {
    fontSize: "0.8rem",
    minHeight: "60px",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.7rem",
    }
  },
  actionBlockLinkContainer: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer"
    }
  },
  actionBlockLinkText: {
    fontSize: "0.8rem",
    fontWeight: "600",
    color: "black",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.7rem",
    }
  },
  actionBlockLinkIcon: {
    fontSize: "30px",
    color: theme.palette.primary.main,
    "@media (max-width: 400px)": {
      fontSize: "20px",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "25px",
    }
  }
}));

export default function HomeComponent() {

  const classes = useStyles();
  const history = useHistory();

  const { loading: loadingDealer, globalDealer } = useContext(DealerContext);
  const { loading: loadingVehicles, vehiclesList } = useContext(VehicleContext);
  const { loading: loadingOffers, offers } = OffersController();

  const onVehiclesSearch = (filteredVehicles, filterState) => {

    history.push("/pre-loved", filterState);

  };


  return (
    <div className={classes.root}>

      <section id="hero-banner" className={classes.heroBannerContainerOuter}>
        <VehicleFilterBanner
          loading={loadingVehicles}
          allVehicles={vehiclesList.filter((v) => v?.brand?.toLowerCase()?.includes("renault"))}
          processFilteredVehicles={onVehiclesSearch}
          headerSmallText={"find your perfect approved used vehicle"}
          headerLargeText={"search for models at your nearest renault selection dealer"}
          heroBannerImg={HeroBannerImg}
        />
      </section>

      <section id="pre-loved" className={classes.preLovedContainer}>

        <Typography variant='h5' gutterBottom className={classes.sectionHeadingSmall}>
          why renault selection pre-loved?
        </Typography>

        <div className={classes.sectionTextContainer}>
          <Typography variant='body1' gutterBottom align='center' className={classes.sectionTextSmall}>
            Selecting a pre-owned vehicle can be an arduous process at times. It does not have to be, which is why Renault Selection offers you quality, reliability and peace-of-mind. Our vehicles go through a rigorous evaluation process, authentication of service history and stringent quality checks to ensure that only the best offering is provided under the Renault Selection Program.
          </Typography>
        </div>

        <div className={classes.offersCarouselContainer}>
          {
            !loadingOffers && offers.length > 0 &&
            <CarouselShared
              slides={offers}
              isOffer
              visibleSlides={3}
              fullWidth={false}
            />
          }
        </div>

      </section>

      {/* <section id="info-blocks" className={classes.infoBlocksContainer}>

                <div className={classes.infoBlockContainer}>
                    <div className={classes.iconContainer}>
                        <img className={classes.icon} src={WarrantyIcon} />
                    </div>

                    <Typography variant='h6' className={classes.infoBlockHeading} gutterBottom>
                        Warranty
                    </Typography>
                    
                    <Typography variant='body1' className={classes.infoBlockText}>
                        All Renault Approved vehicles will come with a minimum of 12 months warranty to give you complete peace of mind. 
                        The warranty will be made up of the balance of manufacturer's warranty topped up, where necessary, to provide a minimum of 12 months cover.
                    </Typography>
                </div>

                <div className={classes.infoBlockContainer}>
                    <div className={classes.iconContainer}>
                        <img className={classes.icon} src={BreakdownIcon} />
                    </div>

                    <Typography variant='h6' className={classes.infoBlockHeading} gutterBottom>
                        12 month breakdown assistance
                    </Typography>
                    
                    <Typography variant='body1' className={classes.infoBlockText}>
                        Your Renault Approved used vehicle will be supplied with 12 months Breakdown Assistance as standard.
                        Should you wish to top up your cover for a longer period your Renault Selection dealer will be happy to help.
                    </Typography>
                </div>

                <div className={classes.infoBlockContainer}>
                    <div className={classes.iconContainer}>
                        <img className={classes.icon} src={ExchangeIcon} />
                    </div>

                    <Typography variant='h6' className={classes.infoBlockHeading} gutterBottom>
                        30 day exchange promise
                    </Typography>
                    
                    <Typography variant='body1' className={classes.infoBlockText}>
                        If you decide within 30 days of purchasing your Renault Approved vehicle that it doesn't meet yur requirements, for whatever reason, your Renault Selection dealer will exchange it for a vehicle of equivalent value. 
                        Should you wish to exchange for a vehicle of a higher value, simply pay the difference. Your statutory rights are not affected.
                    </Typography>
                </div>

                <div className={classes.infoBlockContainer}>
                    <div className={classes.iconContainer}>
                        <img className={classes.icon} src={ChecksIcon} />
                    </div>

                    <Typography variant='h6' className={classes.infoBlockHeading} gutterBottom>
                        Condition checks
                    </Typography>
                    
                    <Typography variant='body1' className={classes.infoBlockText}>
                        Prior to collecting your Renault Approved used vehicle, your Renault Selection dealer will undertake a mechanical and safety check to ensure it is ready for the road and will provide you with a copy of the workshop paperwork.
                    </Typography>
                </div>

            </section> */}

      <section id="action-blocks" className={classes.actionBlocksSectionContainer}>

        <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey", marginBottom: "30px" }}>
          Guiding your journey
        </Typography>

        <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ marginBottom: "25px" }}>
          Buy your next renault online
        </Typography>

        <div className={classes.actionBlocksContainer}>

          <div className={classes.actionBlockContainer}>
            <div className={classes.actionBlockImgContainer}>
              <img className={classes.actionBlockImg} src={JourneyImg1} />
            </div>

            <Typography variant="body2" className={classes.actionBlockSlogan}>
              easy, seamless and transparent
            </Typography>

            <Typography variant="body2" className={classes.actionBlockHeading}>
              buy your used Renault online
            </Typography>

            <Typography variant="body2" className={classes.actionBlockDescription}>
              Now it's even easier to buy your next Approved Used Renault from your nearest Renault Selection dealer.
            </Typography>

            <Link href="/pre-loved" className={classes.actionBlockLinkContainer}>
              <Typography variant="body2" className={classes.actionBlockLinkText}>
                how it works
              </Typography>
              <ChevronRightIcon className={classes.actionBlockLinkIcon} />
            </Link>
          </div>

          <div className={classes.actionBlockContainer}>
            <div className={classes.actionBlockImgContainer}>
              <img className={classes.actionBlockImg} src={JourneyImg2} />
            </div>

            <Typography variant="body2" className={classes.actionBlockSlogan}>
              tailored solutions
            </Typography>

            <Typography variant="body2" className={classes.actionBlockHeading}>
              finance options
            </Typography>

            <Typography variant="body2" className={classes.actionBlockDescription}>
              You've picked your perfect Approved Used Renault, now design your finance. Find everything you need to know about our different finance options.
            </Typography>

            <Link href="/apply-for-finance" className={classes.actionBlockLinkContainer}>
              <Typography variant="body2" className={classes.actionBlockLinkText}>
                explore
              </Typography>
              <ChevronRightIcon className={classes.actionBlockLinkIcon} />
            </Link>
          </div>

          <div className={classes.actionBlockContainer}>
            <div className={classes.actionBlockImgContainer}>
              <img className={classes.actionBlockImg} src={JourneyImg3} />
            </div>

            <Typography variant="body2" className={classes.actionBlockSlogan}>
              everything in one place
            </Typography>

            <Typography variant="body2" className={classes.actionBlockHeading}>
              search approved used Renault
            </Typography>

            <Typography variant="body2" className={classes.actionBlockDescription}>
              Browse through a complete list of Approved Used Renault models available at our Renault Selection dealer network. Filter your search and find your perfect vehicle.
            </Typography>

            <Link href="/pre-loved" className={classes.actionBlockLinkContainer}>
              <Typography variant="body2" className={classes.actionBlockLinkText}>
                explore
              </Typography>
              <ChevronRightIcon className={classes.actionBlockLinkIcon} />
            </Link>
          </div>

        </div>

      </section>

    </div>
  );
}