import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ApplyIcon from '../shared/assets/New/icon_refresh_primary.png';
import PreQualifyIcon from '../shared/assets/New/icon_card_primary.png';
import DriveIcon from '../shared/assets/New/icon_car_primary.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "50px",
    "@media (max-width: 700px)": {
      padding: "25px"
    }
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 700px)": {
      padding: "25px"
    }
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  contentBlocksContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center"
  },
  contentBlock: {
    width: "275px",
    height: "275px",
    padding: "20px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    borderRadius: "10px",
    boxShadow: `0px 0px 5px 0 ${theme.palette.secondary.main}3`,
    "&:hover": {
      boxShadow: `0px 0px 5px 0 ${theme.palette.primary.main}`
    }
  },
  icon: {
    width: "75px"
  }
}));

const UsedVehicleFooter = () => {

  const classes = useStyles();

  return (
    <div className={classes.root}>

      <div className={classes.contentTitleContainer}>
        <Typography variant='h5' className={classes.sectionHeadingSmall}>
          Buy your car in <span style={{ color: "grey" }}>3 easy steps</span>
        </Typography>
      </div>

      <div className={classes.contentBlocksContainer}>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={ApplyIcon} />
          <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ margin: "20px 0" }}>
            Apply
          </Typography>
          <Typography className={classes.sectionTextSmall} align="center">
            Apply online with SA's leading banks
          </Typography>
        </div>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={PreQualifyIcon} />
          <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ margin: "20px 0" }}>
            Pre-qualify
          </Typography>
          <Typography className={classes.sectionTextSmall} align="center">
            Determine your eligibility for financing options and special offers on our vehicles
          </Typography>
        </div>

        <div className={classes.contentBlock}>
          <img className={classes.icon} src={DriveIcon} />
          <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ margin: "20px 0" }}>
            Drive
          </Typography>
          <Typography className={classes.sectionTextSmall} align="center">
            Nationwide delivery of your vehicle to your home or office
          </Typography>
        </div>

      </div>

    </div>
  );
};

export default UsedVehicleFooter;
