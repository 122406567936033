import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import DashboardContainer from './components/MainContainer';
import { MASTER_WEBSITE_COLORS } from './components/shared/Constants';
import { VehicleProvider } from './components/shared/contexts/VehicleContext';
import { DealerProvider } from "./components/shared/contexts/DealerContext";
import { FavouriteProvider } from './components/shared/contexts/FavouriteContext';
import Cookie from './components/shared/CookiePopUp';
import ScrollToTop from './components/shared/ScrollToTop';
import SideNavPopup from './components/sideNavPopup/SideNavPopup';
import RenaultLifeWebRegular from './fonts/RenaultLifeWeb-Regular.woff';
import RenaultLifeWebBold from './fonts/RenaultLifeWeb-Bold.woff';

const renaultRegualar = {
  fontFamily: 'Renault',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Renault'),
    local('Renault-Regular'),
    url(${RenaultLifeWebRegular}) format('woff')
  `
}

const renaultBold = {
  fontFamily: 'Renault',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Renault'),
    local('Renault-Bold'),
    url(${RenaultLifeWebBold}) format('woff')
  `
}

const theme = createTheme({
  palette: {
    primary: {
      main: MASTER_WEBSITE_COLORS.primaryColor
    },
    secondary: {
      main: MASTER_WEBSITE_COLORS.secondaryColor
    },
    background: {
      default: MASTER_WEBSITE_COLORS.accentColor
    }
  },
  typography: {
    fontFamily: [
      'Renault',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTextField: {
      root: {
        marginBottom: '1rem'
      }
    },
    MuiTypography: {
      h4: {
        fontWeight: 700
      },
      h5: {
        textTransform: 'capitalize',
        fontWeight: 700
      },
      h6: {
        fontWeight: 700
      },
      subtitle2: {
        fontSize: '1rem'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '1.5rem 1rem'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#EFDF00',
        color: '#000',
        borderRadius: '0px',
        '&:hover': {
          backgroundColor: '#EFDF00',
          opacity: 0.8
        }
      },
      contained: {
        borderRadius: '0px'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [renaultRegualar, renaultBold],
        body: {
          backgroundColor: '#ffffff',
          fontSize: '100%'
        }
      }
    }
  }
});

const snackProps = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  }
};

function App() {

  localStorage.clear();

  const parsedData = window.location.pathname.split("/");
  const domain = (parsedData.length > 1 && parsedData[1] === "dealer") ? `/dealer/${parsedData[2]}` : "/";

  return (
    <Router basename={domain}>
      <Cookie />
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <SnackbarProvider {...snackProps}>
          <CssBaseline />
          <DealerProvider>
            <VehicleProvider>
              <FavouriteProvider>
                <SideNavPopup />
                <DashboardContainer />
              </FavouriteProvider>
            </VehicleProvider>
          </DealerProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
