import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import LocationIcon from '../shared/assets/New/icon_location.png';
import HeartIcon from '../shared/assets/New/icon_heart.png';
import TelephoneIcon from '../shared/assets/New/icon_telephone.png';

const useStyles = makeStyles((theme) => ({
  root: {
    transform: (hidden) => `translate(${hidden}px, -50% )`,
    position: 'fixed',
    top: '50%',
    right: '0',
    zIndex: '90',
    background: `${theme.palette.primary.main}55`,
    transition: '0.2s all ease-in',
    boxShadow: 'rgb(0 0 0 / 38%) 0px 0px 15px'
  },
  section: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      color: 'black'
    },
    '& a:link': {
      textDecoration: "none"
    },
    '& img': {
      filter: 'blur(1px) brightness(0)'
    },
    '&:hover': {
      background: theme.palette.primary.main,
      '& img': {
        filter: 'blur(0px) brightness(0)'
      },
    }
  },
  sectionIcon: {
    width: "30px",
    aspectRatio: 1
  },
  sectionText: {
    padding: '0px 10px',
    textTransform: 'uppercase'
  }
}));

const SideNavPopup = () => {

  const [hidden, setHidden] = useState(145);
  const classes = useStyles(hidden);

  const showMenu = (e) => {

    e?.stopPropagation();
    setHidden(0);

  };

  const hideMenu = (e) => {

    e?.stopPropagation();
    setHidden(145);

  };

  return (
    <div
      className={classes.root}
      onMouseEnter={showMenu}
      onMouseLeave={hideMenu}
      onClick={hideMenu}
    >

      <div className={classes.section}>
        <Link to="/our-dealers">
          <div className={classes.sectionIcon} >
            <img src={LocationIcon} alt="Our dealers" width="100%" />
          </div>
          <div className={classes.sectionText} >
            Find a dealer
          </div>
        </Link>
      </div>

      <div className={classes.section}>
        <Link to="/my-favourites">
          <div className={classes.sectionIcon} >
            <img src={HeartIcon} alt="My favourites" width="100%" />
          </div>
          <div className={classes.sectionText} >
            My Favourites
          </div>
        </Link>
      </div>

      <div className={classes.section}>
        <Link to="/contact-us">
          <div className={classes.sectionIcon} >
            <img src={TelephoneIcon} alt="Contact us" width="100%" />
          </div>
          <div className={classes.sectionText} >
            Contact Us
          </div>
        </Link>
      </div>

    </div>
  );
};

export default SideNavPopup;