import React from 'react';
import { Route } from 'react-router-dom';
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import NavbarComponent from './NavbarComponent'
import HomeComponent from './homePage/HomeComponent'
import FooterComponent from './homePage/FooterComponent'
import ApplyFinance from './applyFinancePage/ApplyFinance'
import UsedVehicles from './usedVehicles/UsedVehicles'
import ContactUs from './contactUsPage/ContactUs';
import Terms from './legalPage/Terms';
import Privacy from './legalPage/Privacy';
import Cookies from './legalPage/Cookies';
import Popi from './legalPage/Popia';
import OurDealers from './ourDealersPage/OurDealers';
import Favourite from '../components/favouritePage/favouritePage';
import UsedVehicleDetails from './usedVehicles/UsedVehicleDetails';
import SpecialOffers from './offersPage/SpecialOffers';
import SpecialOfferDetails from './offersPage/SpecialOfferDetails';
import ApprovedBenefits from './approvedBenefitsPage/ApprovedBenefits';

export default function DashboardContainer() {

  return (
    <React.Fragment>
      <main>
        <NavbarComponent />
        <Route exact path="/" component={HomeComponent} />
        <Route exact path={["/pre-loved", "/pre-loved/search/:searchField"]} component={UsedVehicles} />
        <Route exact path="/pre-loved/:stockId" component={UsedVehicleDetails} />
        <Route exact path="/approved-benefits" component={ApprovedBenefits} />
        <Route exact path={["/special-offers", "/special-deals", "/campaigns"]} component={SpecialOffers} />
        <Route exact path={["/special-offers/:offerId", "/special-deals/:carId/:modelId/:offerId"]} component={SpecialOfferDetails} />
        <Route exact path={["/campaigns/:offerId", "/campaigns/:carId/:modelId/:offerId"]} render={(props) =>
          <SpecialOfferDetails floorCode='USED' sourceCode='RSA-RSELECT-KWID-CARS' />
        } />
        <Route exact path={["/apply-for-finance", "/apply-for-finance/:stockId", "/apply-for-finance/:offerId"]} component={ApplyFinance} />
        <Route exact path="/our-dealers" component={OurDealers} />
        <Route exact path='/my-favourites' component={Favourite} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route exact path='/terms' component={Terms} />
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/cookies' component={Cookies} />
        <Route exact path='/requestergws' component={Popi} />
        <FooterComponent />
        <ScrollUpButton ToggledStyle={{
          right: "5px",
          opacity: "0.5"
        }} />
      </main>
    </React.Fragment>
  );
}