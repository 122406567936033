import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { ContactUs } from "@red-build/leadcentre";
import { BASE_URI, MASTER_DEALERID, MOTORGROUPID } from "../shared/Constants";
import { currencyFormatter, calculateMonthlyInstallment } from "../shared/UtilityFunctions";
import OffersController from '../shared/OffersController';
import HeroBannerImg from '../shared/assets/New/background_4.png';
import { DealerContext } from "../shared/contexts/DealerContext";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative"
  },
  borderBottomGrey: {
    borderBottom: "1px solid grey"
  },
  borderBottomPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  contentTextLargeBold: {
    fontSize: '22px',
    fontWeight: '600'
  },
  contentTextSmallBold: {
    fontSize: '15px',
    fontWeight: '600',
    "@media (max-width: 550px)": {
      fontSize: "11px",
    },
    "@media (min-width: 551px) and (max-width: 650px)": {
      fontSize: "13px",
    },
    "@media (min-width: 751px) and (max-width: 1000px)": {
      fontSize: "13px"
    }
  },
  contentTextSmall: {
    fontSize: "13px",
    textTransform: "capitalize",
    "@media (max-width: 550px)": {
      fontSize: "9px",
    },
    "@media (min-width: 551px) and (max-width: 650px)": {
      fontSize: "11px",
    },
    "@media (min-width: 751px) and (max-width: 1000px)": {
      fontSize: "11px"
    }
  },
  contentTextVerySmall: {
    fontSize: "10px"
  },
  heroBannerContainerOuter: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    maxHeight: "calc(50vh - 105px)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  heroBannerContainerInner: {
    padding: "20%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      padding: "25px"
    }
  },
  contentContainer: {
    width: "100%",
    padding: "50px 50px",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 500px)": {
      padding: "25px 20px"
    },
    "@media (max-width: 750px)": {
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  contentDetail: {
    padding: "10px 0px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      textOverflow: "clip",
      whiteSpace: "nowrap"
    }
  },
  offerInfoContainer: {
    width: "65%",
    "@media (max-width: 750px)": {
      width: "100%"
    }
  },
  offerBannerContainer: {
    width: "100%"
  },
  offerBannerImg: {
    width: "100%"
  },
  offerDetailsContainer: {
    paddingTop: "20px"
  },
  formContainer: {
    width: "35%",
    marginLeft: "20px",
    "@media (max-width: 750px)": {
      width: "100%",
      margin: "0",
      marginTop: "30px"
    }
  },
  formPadding: {
    padding: "16px 0px 0px 0px"
  },
  formButton: {
    width: "100%"
  },
  secondaryButton: {
    color: "white",
    border: "none",
    background: theme.palette.secondary.main,
    "&:hover": {
      color: "black",
      background: theme.palette.primary.main,
    }
  },
  legalContainer: {
    padding: "50px 0",
    margin: "0 50px",
    textAlignLast: "center",
    borderTop: "1px solid grey",
    "@media (max-width: 500px)": {
      padding: "25px 0",
      margin: "0 20px"
    },
  },
}));

const SpecialOfferDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const isCampaignsPage = history.location.pathname.toLowerCase().includes("campaigns");
  const { loading, offers, hiddenOffers } = OffersController();
  const { dealers, globalDealer } = useContext(DealerContext);
  const [activeOffer, setActiveOffer] = useState({});
  const [dealerList, setDealerList] = useState(dealers);
  const [term, setTerm] = useState(60);
  const [interestRate, setInterestRate] = useState(9);
  const [depositPerc, setDepositPerc] = useState(10);
  const [balloonPerc, setBalloonPerc] = useState(0);
  const [installment, setInstallment] = useState(0);
  const [tcs, setTCs] = useState("");
  const [groupOffers, setGroupOffers] = useState([]);
  const searchParams = useMemo(() => new URLSearchParams(window?.location?.search), []);
  const [sourceCode, setSourceCode] = useState(null);
  /*
   * Lead sources from the db.
   * 2 = facebook
   * 1 = website
   */
  const leadSourceId = `${searchParams.get('utm_source')}`.toLowerCase() === 'facebook' ? 2 : 1;

  const fetchGroupOffers = useCallback(() => {
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      status: 'active',
      motorGroupId: MOTORGROUPID
    };

    axios({
      method: 'GET',
      url: `${BASE_URI}/Offers`,
      params,
    })
      .then((response) => {
        setGroupOffers(response.data.list);
      })
      .catch((e) => console.warn(e));
  }, []);

  // mount only
  useEffect(() => {
    fetchGroupOffers();
  }, [fetchGroupOffers]);

  useEffect(() => {
    if (loading === true) return;
    const offer = (isCampaignsPage === true ? hiddenOffers : offers)?.find(o => o.id == params?.offerId);
    if (!offer?.id > 0) return;

    const tempTerm = offer?.term || term;
    const tempRate = offer?.rate || interestRate;
    const tempDepo = offer?.deposit || depositPerc;
    const tempBalloon = offer?.balloonPayment || balloonPerc;

    setActiveOffer(offer);
    setTerm(tempTerm);
    setInterestRate(tempRate);
    setDepositPerc(tempDepo);
    setBalloonPerc(tempBalloon);
    if (offer?.monthlyInstallment) {
      setInstallment(offer?.monthlyInstallment);
    } else {
      setInstallment(calculateMonthlyInstallment(offer?.price, tempDepo, tempBalloon, tempRate, tempTerm));
    }
    setTCs(
      offer?.termsAndConditions ||
      `While we make every attempt to give you the best possible tools and information, Renault Selection, its agents, employees and accredited 
            financiers will accept no responsibility for any loss or miscalculations that may arise. All calculations made on this calculator are to 
            be used as guidelines only. It is based on the information you have provided and does not take into account monthly expenses and other 
            factors that need consideration when purchasing a vehicle. Amounts displayed here should not be relied upon solely in making your purchase. 
            Please not that by default this calculator uses the prime interest rate +2% and a ${tempTerm}-month loan repayment term to calculate 
            estimated monthly repayment.`
    );
  }, [loading, offers, hiddenOffers, isCampaignsPage, term, interestRate, depositPerc, balloonPerc, params?.offerId]);

  useEffect(() => {
    if (globalDealer && globalDealer?.dealerId !== MASTER_DEALERID) {
      setDealerList([globalDealer]);
    } else {
      if (dealers) {
        // // match on dealers who have the deal enabled using GUID.);
        const offerGUIDMatchedDealerIds = groupOffers
          .filter(go => go.guidKey === activeOffer?.guidKey && go.dealerId !== MASTER_DEALERID)
          .map(go => go.dealerId);
        setDealerList(dealers
          .filter(d => offerGUIDMatchedDealerIds.includes(d.dealerId))
          // remove "Renault" or "Renault Selection" from the dealer names.
          .map(d => ({ ...d, name: d.name.replace(/(Renault)?\s?(Selection)?/, '') }))
        );
        // setDealerList(dealers);
      }
    }
  }, [activeOffer, dealers, globalDealer, groupOffers])

  const firstNotNullValue = (v, n) => v ? v : n;

  useEffect(() => {
    // use utm_content if set, fall back to configured override if set, then use dealer sourceCode provided by site if all others were null.
    setSourceCode([searchParams.get('utm_content'), activeOffer.crmOverride, props?.sourceCode].reduce(firstNotNullValue, null));
  }, [activeOffer?.crmOverride, props?.sourceCode, searchParams]);

  const preQualify = () => {
    history.push("/apply-for-finance", {
      offerId: activeOffer?.id,
      dealerList,
      floorCode: props?.floorCode,
      sourceCode
    });
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.heroBannerContainerOuter}
        style={{ backgroundImage: `linear-gradient( rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.2) ), url(${HeroBannerImg})` }}
      >
        <div className={classes.heroBannerContainerInner}>
          <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
            our exclusive
          </Typography>
          <Typography variant='h5' gutterBottom className={`${classes.sectionHeadingSmall} ${classes.borderBottomPrimary}`}>
            Special offers
          </Typography>
        </div>
      </div>
      {
        loading === true
          ? <div className={classes.contentTitleContainer}>
            <CircularProgress size={100} />
          </div>
          : Object.keys(activeOffer || {}).length <= 0 &&
          <div className={classes.contentTitleContainer}>
            <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey" }}>
              No details available for this offer
            </Typography>
          </div>
      }
      {
        !loading && Object.keys(activeOffer || {}).length > 0 &&
        <>
          <div className={`${classes.contentContainer}`}>
            <div className={classes.offerInfoContainer}>
              <div className={classes.offerBannerContainer}>
                <img className={classes.offerBannerImg} src={activeOffer.imageUrl} alt="" />
              </div>
              <div className={classes.offerDetailsContainer}>
                <Typography className={classes.contentTextLargeBold} gutterBottom>
                  {activeOffer.make} {activeOffer.model}
                </Typography>
                <Typography className={classes.contentTextSmall} gutterBottom>
                  {activeOffer.variant?.replace(" ( - )", "")}
                </Typography>
                <Typography className={classes.contentTextSmall} gutterBottom>
                  {activeOffer.introParagraph} 
                </Typography>
                {/* <Typography className={`${classes.contentTextLargeBold} ${classes.borderBottomGrey}`} style={{ padding: "20px 0" }}>
                  Quick Summary 
                </Typography>
                <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
                  <Typography className={classes.contentTextSmallBold}>
                    Purchase Price
                  </Typography>
                  <Typography className={classes.contentTextSmall}>
                    {currencyFormatter.format(activeOffer.price)}
                  </Typography>
                </div> */}
                {/* if finance deal, show details. */} 
                {!activeOffer.isCashDeal && (<>
                  <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
                    <Typography className={classes.contentTextSmallBold}>
                      Monthly Installment
                    </Typography>
                    <Typography className={classes.contentTextSmall}>
                      {currencyFormatter.format(installment)}
                    </Typography>
                  </div>
                  <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
                    <Typography className={classes.contentTextSmallBold}>
                      Term (Months)
                    </Typography>
                    <Typography className={classes.contentTextSmall}>
                      {activeOffer.term || term}
                    </Typography>
                  </div>
                  <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
                    <Typography className={classes.contentTextSmallBold}>
                      Linked Interest Rate
                    </Typography>
                    <Typography className={classes.contentTextSmall}>
                      {activeOffer.linkedInterest || interestRate}
                    </Typography>
                  </div>
                  <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
                    <Typography className={classes.contentTextSmallBold}>
                      Deposit
                    </Typography>
                    <Typography className={classes.contentTextSmall}>
                      {activeOffer.deposit || depositPerc} %
                    </Typography>
                  </div>
                  <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
                    <Typography className={classes.contentTextSmallBold}>
                      Balloon Payment
                    </Typography>
                    <Typography className={classes.contentTextSmall}>
                      {activeOffer.balloon || balloonPerc} %
                    </Typography>
                  </div>
                </>)}
                <div className={`${classes.contentDetail} ${classes.borderBottomGrey}`}>
                  <Typography className={classes.contentTextSmall} style={{ textAlign: 'justified', whiteSpace: 'pre-wrap' }}>
                    {activeOffer?.termsAndConditions}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.formContainer}>
              <ContactUs
                leadTypeId={activeOffer.leadTypeId || 2}
                leadSourceId={leadSourceId}
                variantId={activeOffer.variantId}
                base_uri={BASE_URI}
                dealerName={activeOffer.dealershipName}
                floorCode={props?.floorCode}
                sourceCode={sourceCode}
                dealers={dealerList}
                dealerType="multi_dealer"
                offerId={activeOffer?.id ? activeOffer.id : null}
              />
              <div className={classes.formPadding}>
                <Button className={`${classes.formButton} ${classes.secondaryButton}`} onClick={preQualify}>
                  pre-qualify now
                </Button>
              </div>
            </div>
          </div>
          <div className={`${classes.legalContainer}`}>
            <Typography className={classes.contentTextSmallBold} gutterBottom>
              Disclaimer*
            </Typography>
            <Typography className={classes.contentTextVerySmall}>
              {tcs}
            </Typography>
          </div>
        </>
      }
    </div>
  );
};

export default SpecialOfferDetails;