import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { ContactUs as ContactForm } from "@red-build/leadcentre";
import { BASE_URI, DEALERID } from "../shared/Constants";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: '#EFDF00',
    width: '33%',
    marginBottom: '0px',
    height: '3px',
    margin: '20px auto'
  },
  bold: {
    fontWeight: 700
  },
  url: {
    color: '#7c7c7c',
    textDecoration: 'none',
    '&:hover': {
      color: '#1f2532',
      textDecoration: 'none'
    }
  },
  link: {
    backgroundColor: '#EFDF00',
    marginBottom: '1rem',
    color: '#ffffff',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8
    }
  }
}));

const ContactUs = () => {
  const classes = useStyles();
  const history = useHistory();
  const { stockId } = history?.location?.state ?? {
    stockId: 0
  };
  const { loading: loadingDealers, globalDealer, dealers } = useContext(DealerContext);
  const { loading: loadingVehicles, vehiclesList } = useContext(VehicleContext);
  const [vehicle, setVehicle] = useState();
  const [allowMultiDealers, setAllowMultiDealers] = useState(true);
  const [dealerId, setDealerId] = useState(0);

  useEffect(() => {
    if (!stockId && !globalDealer) return;

    if (stockId > 0 && !loadingVehicles && vehiclesList?.length > 0) {
      const tempVehicle = vehiclesList.find(v => v.stockId === stockId);
      if (!tempVehicle) return;
      setVehicle(tempVehicle);
      setDealerId(tempVehicle.dealerId);
      setAllowMultiDealers(false);
    } else if (globalDealer?.dealerId > 0 && globalDealer.dealerId !== DEALERID) {
      setDealerId(globalDealer.dealerId);
      setAllowMultiDealers(false);
    }
  }, [loadingVehicles, vehiclesList, globalDealer]);

  const dealerAddress = dealers.find(d=>d.dealerId===dealerId)?.address?.streetAddress || globalDealer?.address?.streetAddress;
  const dealerPhone = dealers.find(d=>d.dealerId===dealerId)?.usedCarsPhoneNumber || globalDealer?.usedCarsPhoneNumber;

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
          Our Customer Relations team are here to help you
        </Typography>
        <Divider className={classes.dividerTitle} />
        <Box mb={3} mt={3}>
          <Box textAlign="center" fontSize={'1.5rem'}>
            Send us your details and we will call you
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box fontWeight="fontWeightBold">General Enquiries</Box>
            <Box mb={3}>{dealerPhone || 'Not Provided'}</Box>
            <Box fontWeight="fontWeightBold">Address</Box>
            <Box mb={3}>{dealerAddress ||  "Not Provided"}</Box>
            <Box fontWeight="fontWeightBold">Opening Hours</Box>
            <Box>Monday - Friday 07:30 - 17:30</Box>
            <Box>Saturday: 08:00 - 13:00</Box>
            <Box mb={3}>Sunday: Closed</Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactForm
              leadTypeId={!vehicle ? 3 : 2}
              base_uri={BASE_URI}
              stockId={vehicle?.stockId}
              dealerType={allowMultiDealers ? "multi_dealer" : "single_dealer"}
              dealers={allowMultiDealers ? dealers : []}
              dealerId={allowMultiDealers ? 0 : dealerId}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default ContactUs;