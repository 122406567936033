import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Finance } from "@red-build/leadcentre";
import { BASE_URI, DEALERID, FINAPP_URI_APPLICATION } from "../shared/Constants";
import OffersController from '../shared/OffersController';
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import HeroBannerImg from '../shared/assets/New/background_6.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    paddingBottom: "50px",
    "@media (max-width: 550px)": {
      paddingBottom: "25px"
    },
  },
  borderBottomGrey: {
    borderBottom: "1px solid grey"
  },
  borderBottomPrimary: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionText: {
    fontSize: "1.2rem",
    "@media (max-width: 400px)": {
      fontSize: "0.8rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  heroBannerContainerOuter: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    maxHeight: "calc(50vh - 105px)",
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroBannerContainerInner: {
    padding: "20%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      padding: "25px"
    }
  },
  contentTitleDescription: {
    width: "100%",
    paddingTop: "15px",
    "@media (max-width: 500px)": {
      padding: "10px"
    }
  },
  formContainer: {
    width: "100%",
    padding: "0 15%",
    "@media (max-width: 800px)": {
      padding: "0 5%",
    }
  }
}));

const ApplyFinance = () => {
  const classes = useStyles();
  const history = useHistory();

  const { stockId, offerId, floorCode, sourceCode, dealerList } = history?.location?.state ?? {
    stockId: 0,
    offerId: 0,
    floorCode: null,
    sourceCode: null,
    dealerList: []
  };

  const { loading: loadingDealers, globalDealer, dealers } = useContext(DealerContext);
  const { loading: loadingVehicles, vehiclesList } = useContext(VehicleContext);
  const { loading: loadingOffers, offers } = OffersController();

  const [vehicle, setVehicle] = useState();
  const [offer, setOffer] = useState();
  const [allowMultiDealers, setAllowMultiDealers] = useState(true);
  const [dealerId, setDealerId] = useState(0);

  useEffect(() => {
    if (!stockId && !offerId && !globalDealer) return;

    if (stockId > 0 && !loadingVehicles && vehiclesList?.length > 0) {
      const tempVehicle = vehiclesList.find(v => v.stockId == stockId);
      if (!tempVehicle) return;
      setVehicle(tempVehicle);
      setDealerId(tempVehicle.dealerId);
      setAllowMultiDealers(false);
    } else if (offerId > 0 && !loadingOffers && offers?.length > 0) {
      const tempOffer = offers.find(o => o.id == offerId);
      if (!tempOffer) return;
      setOffer(tempOffer);
      setDealerId(tempOffer.dealerId);
      setAllowMultiDealers(true);
    } else if (globalDealer?.dealerId > 0 && globalDealer.dealerId !== DEALERID) {
      setDealerId(globalDealer.dealerId);
      setAllowMultiDealers(false);
    }

  }, [loadingVehicles, loadingOffers, vehiclesList, offers, globalDealer]);

  return (
    <div className={classes.root}>
      <div
        className={classes.heroBannerContainerOuter}
        style={{ backgroundImage: `linear-gradient( rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.2) ), url(${HeroBannerImg})` }}
      >
        <div className={classes.heroBannerContainerInner}>
          <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
            get your online
          </Typography>
          <Typography variant='h5' gutterBottom className={`${classes.sectionHeadingSmall} ${classes.borderBottomPrimary}`}>
            Finance pre-qualified
          </Typography>
        </div>
      </div>
      <div className={classes.contentTitleContainer}>
        <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey" }}>
          Need Finance?
        </Typography>
        <div className={classes.contentTitleDescription}>
          <Typography className={classes.sectionText} align="center">
            Leave your details, we will get back to you
          </Typography>
        </div>
      </div>
      <div className={classes.formContainer}>
        <Finance
          leadTypeId={2}
          pqType={"interim_pq"}
          base_uri={BASE_URI}
          stockId={vehicle?.stockId}
          mmCode={vehicle?.mmCode}
          offerId={offer?.id}
          dealerType={allowMultiDealers ? "multi_dealer" : "single_dealer"}
          dealers={allowMultiDealers ? (offer ? dealerList : dealers) : []}
          dealerId={allowMultiDealers ? 0 : dealerId}
          pqSuccessUrl={FINAPP_URI_APPLICATION}
          floorCode={floorCode}
          sourceCode={sourceCode}
          masterDealerId={DEALERID}
        />
      </div>
    </div>
  )
};

export default ApplyFinance;