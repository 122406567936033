import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Button, MenuItem } from '@material-ui/core';

export default function VehicleFilterSortButton({
  buttonProps,
  menuProps,
  menuItems,
  selectItem,
  selectedItemId
}) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelect = (menuItem) => {

    selectItem(menuItem);
    handleClose();

  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        className={buttonProps.className}
      >
        {buttonProps.label || "Sort By"}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        className={menuProps.menuClassName}
        PopoverClasses={{ paper: menuProps.menuClassName }}
      >
        {
          menuItems?.map(m => (
            <MenuItem
              key={m.id}
              onClick={() => handleItemSelect(m)}
              className={`${menuProps.itemClassName} ${m.id === selectedItemId ? menuProps.selectedItemClassName : ""}`}
            >
              {m.label}
            </MenuItem>
          ))
        }
      </Menu>
    </React.Fragment>
  );
}