import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { PAGE_SIZE } from "../shared/Constants";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { FavouriteContext } from "../shared/contexts/FavouriteContext";
import { currencyFormatter, mileageFormatter, calculateMonthlyInstallment } from "../shared/UtilityFunctions";
import VehicleFilterBanner from "../shared/vehicleFilter/VehicleFilterBanner";
import UsedVehicleFooter from "./UsedVehicleFooter";
import HeroBannerImg from '../shared/assets/New/background_2.png';
import ComingSoon from '../shared/assets/New/vehicle_card_default.png';
import HeartIcon from '../shared/assets/New/icon_heart.png';
import HeartActiveIcon from '../shared/assets/New/icon_heart_filled.png';
import OdometerIcon from '../shared/assets/New/icon_mileage.png';
import TransmissionIcon from '../shared/assets/New/icon_transmission.png';
import ConditionIcon from '../shared/assets/New/icon_vehicle_condition.png';
import FuelTypeIcon from '../shared/assets/New/icon_fuel_type.png';
import WarrantyIcon from '../shared/assets/New/icon_warranty_2.png';
import LocationIcon from '../shared/assets/New/icon_location.png';
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative"
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  filterBannerContainer: {
    width: "100%",
  },
  contentTitleContainer: {
    width: "100%",
    padding: "50px",
    display: "flex",
    justifyContent: "center"
  },
  vehiclesContainer: {
    width: "100%",
    padding: "50px 20px",
    paddingTop: "0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around"
  },
  vehicleCard: {
    width: "calc(100% / 3 - 20px )",
    margin: "20px auto",
    padding: "15px",
    boxShadow: `0px 3px 5px 0px ${theme.palette.secondary.main}`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    "@media (max-width: 500px)": {
      width: "100%",
    },
    "@media (min-width: 501px) and (max-width: 700px)": {
      width: "80%",
    },
    "@media (min-width: 701px) and (max-width: 999px)": {
      width: "calc(100% / 2 - 20px )",
    },
  },
  cardImgContainer: {
    width: "100%",
    position: "relative"
  },
  cardImg: {
    width: "100%"
  },
  favouriteIconContainer: {
    width: "25px",
    height: "25px",
    margin: "10px",
    position: "absolute",
    zIndex: 10,
    top: 0,
    right: 0,
    "&:hover": {
      cursor: "pointer",
      "& $favouriteIcon": {
        display: "none"
      },
      "& $favouriteIconActive": {
        display: "block"
      }
    }
  },
  favouriteIcon: {
    width: "100%",
  },
  favouriteIconActive: {
    display: "none"
  },
  cardDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px"
  },
  cardTitleContainer: {
    marginBottom: '10px'
  },
  cardTitle: {
    fontSize: '1.2em',
    fontWeight: '600',
    textTransform: "uppercase"
  },
  cardTitleDescription: {
    fontSize: "0.8em"
  },
  cardPriceContainer: {
    marginBottom: '10px'
  },
  cardPrice: {
    fontSize: '1.8em',
    fontWeight: '600'
  },
  cardInstallment: {
    fontSize: "0.8em"
  },
  cardTextGroupContainer: {
    width: "100%",
    display: 'flex',
    alignItems: "center",
    margin: '5px 0px'
  },
  cardDetailIcon: {
    width: "15px",
    marginRight: "20px",
    filter: "brightness(0)"
  },
  cardDetail: {
    fontSize: "0.8em",
    textTransform: "capitalize"
  },
  cardDetailBold: {
    fontWeight: "600"
  },
  cardButtonGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    marginTop: "20px"
  },
  cardButton: {
    width: "48%",
  },
  primaryButton: {
    color: "black",
    border: "none",
    background: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
    }
  },
  secondaryButton: {
    color: "white",
    border: "none",
    background: theme.palette.secondary.main,
    "&:hover": {
      color: "black",
      background: theme.palette.primary.main,
    }
  },
  transparentButton: {
    background: "transparent",
    border: "1px solid black",
    "&:hover": {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
  navButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center"
  },
  navBtn: {
    width: "100px",
    margin: '10px'
  },
  pageNumberContainer: {
    fontSize: "0.8em",
    margin: '10px',
    textAlign: 'center'
  },
}));

const UsedVehicles = () => {
  const classes = useStyles();
  const history = useHistory();
  const { searchField } = useParams();
  const vehicleFilterState = history.location?.state;
  const { loading: loadingVehicles, vehiclesList } = useContext(VehicleContext);
  const { globalDealer } = useContext(DealerContext);
  const { addFavourite, removeFavourite } = useContext(FavouriteContext);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [vehiclesPage, setVehiclesPage] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setCount] = useState(PAGE_SIZE);

  useEffect(() => {
    if (loadingVehicles === true) return;
    let page = []
    let startIndex = 0
    let endIndex = PAGE_SIZE

    if ((globalDealer.dealerId == 330) || (globalDealer.dealerId == 329)) {
      for (let index = 0; index <= filteredVehicles?.filter((v) => v.brand == "Renault")?.length / PAGE_SIZE; index++) {
        page?.push(filteredVehicles?.filter((v) => v.brand == "Renault")?.slice(startIndex, endIndex))
        startIndex = endIndex
        endIndex = endIndex + PAGE_SIZE
      }
    } else {
      for (let index = 0; index <= filteredVehicles?.length / PAGE_SIZE; index++) {
        if (filteredVehicles?.slice(startIndex, endIndex).length >= 1) {
          page?.push(filteredVehicles?.slice(startIndex, endIndex))
        }
        startIndex = endIndex
        endIndex = endIndex + PAGE_SIZE
      }
    }

    setVehiclesPage(page)
  }, [loadingVehicles, filteredVehicles, globalDealer]);

  useEffect(() => {
    if (loadingVehicles === true) return;
    if (!vehicleFilterState || Object.keys(vehicleFilterState)?.length <= 0) {
      setFilteredVehicles(vehiclesList);
    }
  }, [loadingVehicles, vehiclesList]);

  useEffect(() => {
    if (searchField?.length > 0) {
      setFilteredVehicles(vehiclesList.filter(v => (v?.model || v?.modelRange)?.toLowerCase().includes(searchField.toLowerCase())));
    }
  }, [searchField]);

  const onVehiclesSearch = (searchedVehicles, searchState) => {
    setFilteredVehicles(searchedVehicles);
  };

  const toggleFavourite = (vehicle) => {
    if (vehicle.isFavourite === true) {
      removeFavourite(vehicle);
    } else {
      addFavourite(vehicle);
    }
  };

  const navigateEnquire = (stockId) => {
    history.push("/contact-us", { stockId: stockId });
  };

  const navigateDetail = (stockId) => {
    history.push(`/pre-loved/${stockId}`);
  };

  const handleNextPage = () => {
    if (vehiclesPage.length - 1 > pageIndex) {
      setCount(count + PAGE_SIZE)
      setPageIndex(pageIndex + 1)
    }
  };

  const handleBackPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1)
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.filterBannerContainer}>
        <VehicleFilterBanner
          loading={loadingVehicles}
          allowSort={true}
          allVehicles={vehiclesList}
          filterState={vehicleFilterState}
          processFilteredVehicles={onVehiclesSearch}
          headerSmallText={"Renault Selection Pre-Loved"}
          headerLargeText={`${filteredVehicles?.length || 0} vehicles available`}
          heroBannerImg={HeroBannerImg}
        />
      </div>

      <div className={classes.contentTitleContainer}>
        {
          loadingVehicles === true
            ? <CircularProgress size={100} />
            : <Typography variant='h5' className={classes.sectionHeadingSmall} style={{ color: "grey" }}>
              {vehiclesPage[pageIndex]?.length > 0 ? "Results of models available" : "No results available"}
            </Typography>
        }

      </div>
      {
        !loadingVehicles && vehiclesPage[pageIndex]?.length > 0 &&
        <>
          <div className={classes.vehiclesContainer}>
            {
              vehiclesPage[pageIndex].map((v, index) => (
                <div key={index} className={classes.vehicleCard} >
                  <div className={classes.cardImgContainer}>
                    <img className={classes.cardImg} src={v?.image || ComingSoon} />
                    <div className={classes.favouriteIconContainer} onClick={() => toggleFavourite(v)}>
                      <img className={classes.favouriteIcon} src={v.isFavourite === true ? HeartActiveIcon : HeartIcon} />
                      <img className={`${classes.favouriteIcon} ${classes.favouriteIconActive}`} src={v.isFavourite === true ? HeartIcon : HeartActiveIcon} />
                    </div>
                  </div>
                  <div className={classes.cardDetailsContainer}>
                    <div className={classes.cardTitleContainer}>
                      <Typography className={classes.cardTitle}>{v?.year} {v?.brand} {v?.model}</Typography>
                      <Typography className={classes.cardTitleDescription}>{v?.modelRange}</Typography>
                    </div>
                    <div className={classes.cardPriceContainer}>
                      <Typography className={classes.cardPrice}>{currencyFormatter.format(v?.price)}</Typography>
                      <Typography className={classes.cardInstallment}>
                        {currencyFormatter.format(calculateMonthlyInstallment(v?.price, 10, 0, 9, 60))}&nbsp;
                        pm | 10% Deposit | Term 60 Months | 9% Interest
                      </Typography>
                    </div>
                    <div className={classes.cardTextGroupContainer}>
                      <img className={classes.cardDetailIcon} src={OdometerIcon} />
                      <Typography className={classes.cardDetail}>
                        Odometer:&nbsp;
                        {mileageFormatter.format(v?.mileage)}
                      </Typography>
                    </div>
                    <div className={classes.cardTextGroupContainer}>
                      <img className={classes.cardDetailIcon} src={TransmissionIcon} />
                      <Typography className={classes.cardDetail}>
                        Transmission:&nbsp;
                        {v?.transmission ? v?.transmission : 'N/A'}
                      </Typography>
                    </div>
                    <div className={classes.cardTextGroupContainer}>
                      <img className={classes.cardDetailIcon} src={ConditionIcon} />
                      <Typography className={classes.cardDetail}>
                        Condition:&nbsp;
                        {v?.newUsed}
                      </Typography>
                    </div>
                    <div className={classes.cardTextGroupContainer}>
                      <img className={classes.cardDetailIcon} src={FuelTypeIcon} />
                      <Typography className={classes.cardDetail}>
                        Fuel Type:&nbsp;
                        {v?.fuelType ? v?.fuelType?.trim() : 'N/A'}
                      </Typography>
                    </div>
                    <div className={classes.cardTextGroupContainer}>
                      <img className={classes.cardDetailIcon} src={WarrantyIcon} />
                      <Typography className={classes.cardDetail}>
                        Balance of Manufacturer Warranty*
                      </Typography>
                    </div>
                    <div className={classes.cardTextGroupContainer}>
                      <img className={classes.cardDetailIcon} src={LocationIcon} />
                      <Typography className={`${classes.cardDetail} ${classes.cardDetailBold}`}>
                        Location:&nbsp;
                        {v?.dealershipName}
                      </Typography>
                    </div>
                    <div className={classes.cardButtonGroupContainer}>
                      <Button className={`${classes.cardButton} ${classes.transparentButton}`} onClick={() => { navigateEnquire(v?.stockId) }}>
                        enquire now
                      </Button>
                      <Button className={`${classes.cardButton} ${classes.secondaryButton}`} onClick={() => { navigateDetail(v?.stockId) }}>
                        view details
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className={classes.navButtonContainer}>
            <Button className={`${classes.navBtn} ${classes.primaryButton}`} onClick={handleBackPage}>
              Back
            </Button>
            <div className={classes.pageNumberContainer}>
              {pageIndex + 1} / {vehiclesPage?.length}
            </div>
            <Button className={`${classes.navBtn} ${classes.primaryButton}`} onClick={handleNextPage}>
              Next
            </Button>
          </div>
        </>
      }
      <UsedVehicleFooter />∂
    </div>
  );
};

export default UsedVehicles;