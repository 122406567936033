import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import VehicleFilter from "./VehicleFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative"
  },
  sectionHeadingSmall: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "1.2rem",
    }
  },
  sectionTextSmall: {
    fontSize: "1rem",
    textTransform: "capitalize",
    "@media (max-width: 400px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 401px) and (max-width: 550px)": {
      fontSize: "0.8rem",
    }
  },
  heroBannerContainerOuter: {
    "@media (min-width: 950px)": {
      maxHeight: "calc(50vh - 105px)",
    },
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  heroBannerContainerInner: {
    padding: "10%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  filterContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
}));

const VehicleFilterBanner = ({
  loading,
  allowSort,
  allVehicles,
  filterState,
  processFilteredVehicles,
  headerSmallText,
  headerLargeText,
  heroBannerImg
}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>

      <div
        className={classes.heroBannerContainerOuter}
        style={{ backgroundImage: `linear-gradient( rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.2) ), url(${heroBannerImg})` }}
      >
        <div className={classes.heroBannerContainerInner}>
          <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
            {headerSmallText}
          </Typography>
          <Typography variant='h5' gutterBottom className={classes.sectionHeadingSmall}>
            {headerLargeText}
          </Typography>

          <div className={classes.filterContainer}>
            {
              loading === true
                ? <CircularProgress />
                : allVehicles?.length > 0
                  ? <VehicleFilter
                    allowSort={allowSort}
                    allVehicles={allVehicles}
                    filterState={filterState}
                    processFilteredVehicles={processFilteredVehicles}
                  />
                  : <Typography variant='body1' gutterBottom className={classes.sectionTextSmall} align="center">
                    no vehicles available
                  </Typography>
            }
          </div>
        </div>
      </div>

    </div>
  );
};

export default VehicleFilterBanner;