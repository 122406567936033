import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI, DEALERID } from "./Constants"
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";
import { Switch } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from '@material-ui/core/Box';
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%"
  },
  flex: {
    display: "flex"
  },
  button: {
    width: "100%",
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  input: {
    padding: '19px 10px',
    width: '100%',
    borderRadius: '0px',
    border: '1px black solid',
    color: '#353535de',
    fontWeight: '600'
  },
  inputHolder: {
    width: '100%',
  },

}));

export default function ContactComponentPopi(props) {
  const classes = useStyles();
  const [tsAndCs, setTsAndCs] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false)
  const types = [
    {
      id: 1,
      name: 'Customer'
    },
    {
      id: 2,
      name: 'Vistor'
    },
    {
      id: 3,
      name: 'Job Candidate'
    },
    {
      id: 4,
      name: 'Employee'
    },
    {
      id: 5,
      name: 'Vendor'
    },
    {
      id: 6,
      name: 'Other'
    }
  ];

  const requestTypes = [
    {
      id: 1,
      name: 'Complaint'
    },
    {
      id: 2,
      name: 'Delete My Personal Information'
    },
    {
      id: 3,
      name: 'Provide All My Personal Information'
    },
    {
      id: 4,
      name: 'List All Types(s) of My Data Collected'
    },
    {
      id: 5,
      name: 'List All Sources of My Data'
    },
    {
      id: 6,
      name: 'What is the Purpose of Collecting My Data?'
    },
    {
      id: 7,
      name: 'Give Us a Notice of Action'
    }
  ];

  let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  const values = {
    name: '',
    phone: '',
    email: ''
  }

  const validationSchema = Yup.object({
    name: Yup.string("Enter a name").required(
      "Name is required"
    ),
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    phone: Yup.string("Enter your phone number")
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
  });

  const checkValid = (validForm) => {
    return (validForm && tsAndCs)
  }

  function createRequest(values) {

    setLoader(true);

    let params = {}

    params.dealerId = values.dealerId || DEALERID
    params.name = values.name
    params.phoneNumber = values.phone
    params.emailAddress = values.email
    params.notes = values.notes
    params.typeId = values.typeId;
    params.requestTypeId = values.requestTypeId;

    let m = new Date()
    m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
    params.date = new Date().toISOString();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: 'POST',
      url: `${BASE_URI}/popirequest`,
      data: params,
      cancelToken: source.token
    }).then((response) => {

      setLoader(false);
      enqueueSnackbar(`Successfully sent request`, { variant: 'success' });

    }).catch(error => {
      if (axios.isCancel(error)) return
      enqueueSnackbar("Unable to complete the request", { variant: 'error' });
      setLoader(false);
    })
  }

  const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setFieldValue(fieldName, e.target.value)
    setFieldValue(fieldId, value.props.id)
  }

  return (
    <React.Fragment>
      <MuiThemeProvider>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Card className={classes.content} elevation={0}>
              <CardContent>
                <Formik
                  isInitialValid={false}
                  initialValues={values}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    createRequest(values)
                  }}
                >
                  {(props) => {
                    let {
                      values: {
                        name,
                        phone,
                        email
                      },
                      touched,
                      errors,
                      handleBlur,
                      isValid,
                      submitForm,
                      setFieldTouched,
                      setFieldValue
                    } = props;
                    return (
                      <Form>
                        <Grid container className={classes.root} direction="row">

                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            lg={12}>
                            <p>I am requesting as a (an)</p>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="typeId"
                              select
                              //required
                              label=""
                              value={values.typeId}
                              helperText={errors.typeId && touched.typeId ? errors.typeId : ''}
                              error={errors.typeId && touched.typeId}
                              onBlur={handleBlur("typeId")}
                              onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'typeId', 'typeId')}
                            >
                              {types.map((option) => (
                                <MenuItem key={option.id} id={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}

                            </TextField>
                          </Grid>
                          <Box mb={2} className={classes.inputHolder}>
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                              lg={12} >
                              <p>Privacy request type</p>
                              <TextField
                                variant="outlined"
                                fullWidth
                                id="requestTypeId"
                                select
                                //required
                                label=""
                                value={values.requestTypeId}
                                helperText={errors.requestTypeId && touched.requestTypeId ? errors.requestTypeId : ''}
                                error={errors.requestTypeId && touched.requestTypeId}
                                onBlur={handleBlur("requestTypeId")}
                                onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'requestTypeId', 'requestTypeId')}
                              >
                                {requestTypes.map((option) => (
                                  <MenuItem key={option.id} id={option.id} value={option.name}>
                                    {option.name}
                                  </MenuItem>
                                ))}

                              </TextField>
                            </Grid>
                          </Box>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                          >
                            <TextField
                              id="name"
                              name="name"
                              label="Name"
                              value={name}
                              fullWidth
                              variant="outlined"
                              helperText={touched.name ? errors.name : ""}
                              error={touched.name && Boolean(errors.name)}
                              onChange={(event) => {
                                setFieldValue("name", event.target.value)
                                setFieldTouched("name", true, false);
                              }}
                            />
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            lg={12}>
                            <TextField
                              id="email"
                              name="email"
                              type="email"
                              label="Email"
                              fullWidth
                              value={email}
                              variant="outlined"
                              helperText={touched.email ? errors.email : ""}
                              error={touched.email && Boolean(errors.email)}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value)
                                setFieldTouched("email", true, false);
                              }}
                            />{" "}
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            lg={12}>
                            <TextField
                              id="phone"
                              name="phone"
                              type="tel"
                              label="Cellphone"
                              fullWidth
                              value={phone}
                              variant="outlined"
                              helperText={touched.phone ? errors.phone : ""}
                              error={touched.phone && Boolean(errors.phone)}
                              onChange={(event) => {
                                setFieldValue("phone", event.target.value)
                                setFieldTouched("phone", true, false);
                              }}
                            />
                          </Grid>

                          <Box className={classes.inputHolder}>
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            >
                              <TextField
                                id="notes"
                                name="notes"
                                label="Notes"
                                type="textarea"
                                fullWidth
                                variant="outlined"
                                helperText={touched.notes ? errors.notes : ""}
                                error={touched.notes && Boolean(errors.notes)}
                                onChange={(event) => {
                                  setFieldValue("notes", event.target.value)
                                  setFieldTouched("notes", true, false);
                                }}

                              />
                            </Grid>
                          </Box>


                          <Grid item className={classes.flex} xs={12} md={12}
                            lg={12}>
                            <FormControlLabel
                              control={
                                <Field component={Switch}
                                  color="primary"
                                  type="checkbox"
                                  name="popi"
                                  onClick={() => {
                                    setTsAndCs(tsAndCs ? false : true)
                                  }}
                                />
                              }

                            />
                            <span>
                              By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.link}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.link}>privacy policies.</Link>
                            </span>
                          </Grid>
                          <Grid container direction="row">
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                              lg={12}>

                              {!loader &&
                                <Button className={classes.button} variant={"contained"}
                                  color="primary"
                                  disabled={!checkValid(isValid)}
                                  onClick={() => {
                                    submitForm();
                                  }}
                                >
                                  Contact Me
                                </Button>
                              }
                              {loader == true && (
                                <Button className={classes.button} variant={"contained"}
                                  color="primary"
                                  disabled={true}
                                >
                                  <CircularProgress />
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </React.Fragment>
  );
}