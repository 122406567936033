import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, MOTORGROUPID, MASTER_DEALERID } from '../Constants.js';
import { DealerContext } from './DealerContext.js';

export const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {

  let cancel;

  const { globalDealer } = useContext(DealerContext);
  const [loading, setLoading] = useState(true);
  const [vehiclesList, setVehiclesList] = useState([]);

  useEffect(() => {

    if (!globalDealer?.dealerId) return;

    Promise.allSettled([
      getUsedVehicles()
    ]).then(() => {

      setLoading(false);

    });

    return () => cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDealer]);

  const getUsedVehicles = async () => {

    let params = {
      pageNumber: 1,
      pageSize: 5000
    }

    if (globalDealer.dealerId != MASTER_DEALERID) {

      params.dealerId = globalDealer.dealerId;

    } else {

      params.motorGroupId = MOTORGROUPID;

    }

    try {

      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/stock`,
        params,
        cancelToken: new axios.CancelToken(c => cancel = c)
      });

      // const exemptedDealerIDs = [ 329, 330 ];     // dealers exempted from the following filter-rule
      const data = response?.data?.list?.filter(v => v?.brand?.toLowerCase()?.includes("renault"));
      const uniqueData = removeDuplicates(data, it => it.stockNo);
      const sortedData = uniqueData?.sort((a, b) => (a.model || a.modelRange) > (b.model || b.modelRange) ? 1 : -1);

      setVehiclesList(sortedData);

    } catch (error) {
      if (axios.isCancel(error)) return;
    }

  };

  function removeDuplicates(data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ];
  }

  return (
    <VehicleContext.Provider value={{ loading, vehiclesList }}>
      {children}
    </VehicleContext.Provider>
  )
};