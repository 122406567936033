export const BASE_URI = process.env.REACT_APP_CONEXA_API_URL;
//export const BASE_URI = "http://localhost:56746/api"
export const SELF_URI = "http://localhost:3000/renault"
export const PAGE_SIZE = 20;
export const HAS_MULTIPLE_DEALERSHIPS = false
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;

export const DEALERID = 277
export const MASTER_DEALERID = 277
export const MASTER_DEALER_NAME = "Renault Selection Master"
export const MOTORGROUPID = 66

export const VIRTUAL_URI_RANGE = `https://configurator.renaultmotors.co.za/${DEALERID}/range/:leadId`
export const VIRTUAL_URI_DERIVATIVE = `https://configurator.renaultmotors.co.za/${DEALERID}/:modelId/builder/:leadId`
export const VIRTUAL_URI_APPLICATION = `https://configurator.renaultmotors.co.za/${DEALERID}/1/vehicle/finance/0/:leadId`

export const FINAPP_URI_BASE = "https://dz6rhlcy38qhy.cloudfront.net";
export const FINAPP_URI_APPLICATION = `${FINAPP_URI_BASE}/${DEALERID}/1/vehicle/finance/0/:leadId`;

export const MASTER_WEBSITE_COLORS = {
  primaryColor: '#EFDF00',
  secondaryColor: '#000',
  accentColor: '#EFDF00'
};

export const MASTER_DEALER = {
  dealerId: MASTER_DEALERID,
  name: MASTER_DEALER_NAME,
  websiteColors: MASTER_WEBSITE_COLORS
};